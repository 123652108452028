import axiosInstance from "../../../helpers/api";
export const studentsPerGroup = (id) => {
  return axiosInstance.get(`/admin/Reports/group/${id}`);
};

export const videoReport = (id) => {
  return axiosInstance.get(`/admin/Reports/video/${id}`);
};

export const attendanceReport = (data) => {
  return axiosInstance.get(
    `/admin/Reports/attend/${data?.lecId}/${data?.date}`
  );
};

export const examReport = (id) => {
  return axiosInstance.get(`/admin/Reports/exam/${id}`);
};

export const unpaidReport = (id) => {
  return axiosInstance.get(`/admin/Reports/students/not_paid/${id}`);
};
export const paidReport = (id) => {
  return axiosInstance.get(`/admin/Reports/paid_students/${id}`);
};
export const examAttendace = (id) => {
  return axiosInstance.get(`/admin/Reports/examAttend/${id}`);
};

export const homeworkAttendace = (query) => {
  return axiosInstance.get(`/admin/Reports/homework?${query}`);
};
export const homeworkUncomment = (id) => {
  return axiosInstance.get(
    `/admin/student_homeworks/list_uncommented_hw/${id}`
  );
};
export const filterhomeworkAttendace = ({ homeworkId, groupId }) => {
  return axiosInstance.get(
    `/admin/Reports/homework/${homeworkId}/group/${groupId}`
  );
};

export const homeworkAbsent = (id) => {
  return axiosInstance.get(`/admin/Reports/homeworkAttend/${id}`);
};

export const deleteStudentHomework = (data) => {
  return axiosInstance.delete(
    `/admin/student_homeworks/delete_student_homework/${data.student_id}/${data.homework_id}`
  );
};

export const restrictStudentHomework = (homework_id) => {
  return axiosInstance.put(`/admin/homeworks/restrict_group/${homework_id}`);
};

export const acceptStudentHomework = (homework_id) => {
  return axiosInstance.put(`/admin/homeworks/acceptAll/${homework_id}`);
};

export const commentStudentHomework = (payload) => {
  return axiosInstance.put(`/admin/student_homeworks/correct`, payload?.body);
};

export const studentPerformance = (id) => {
  return axiosInstance.get(`/admin/payments/${id}`);
};
