import {
  Avatar,
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/student/Layout/layout";
import { getAllStudentChaptersRequest } from "../../../modules/students-modules/chapters/Actions";
const ModelAnswersChaptersList = ({ t }) => {
  const baseUrl = "https://asquera.com/asquera";
  const navigate = useNavigate();

  const chapters = useSelector(
    (state) => state.studentChapters.studentChapters
  );
  const isLoading = useSelector((state) => state.studentChapters.isLoading);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllStudentChaptersRequest());
  }, []);
  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start">
          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            PDFs
          </Heading>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={6}
            w="100%"
          >
            {chapters?.map((chapter, index) => {
              return (
                <Flex
                  key={index}
                  flexDirection={"row"}
                  borderRadius="10px"
                  bg="#ededed"
                  gap={2}
                  p="10px"
                  cursor="pointer"
                  alignItems="center"
                  onClick={() =>
                    navigate(
                      `/model-answer/${chapter?.id}?name=${chapter?.name}`
                    )
                  }
                >
                  <Avatar
                    name={chapter?.name}
                    src={baseUrl + "/" + chapter?.logo}
                    borderRadius="8px"
                    size="lg"
                    background="transparent"
                  />
                  <Box>
                    <Text fontWeight="bold" fontSize="20px">
                      {chapter?.name}
                    </Text>
                    {/* <Text fontWeight="400" fontSize="18px">
                      {chapter?.lessons_count} {t("lessons")}
                    </Text> */}
                  </Box>
                </Flex>
              );
            })}
          </Grid>
        </VStack>
      </Container>
    </Layout>
  );
};

export default withTranslation()(ModelAnswersChaptersList);
