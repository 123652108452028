import { Box, Checkbox, CheckboxGroup, Heading, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import MainModel from "../../../../components/Models/main-model";
import {
  createExamQuestionsRequest,
  getAllExamQuestionsRequest,
} from "../../../../modules/admin-modules/exams/Actions";
import { closeQuestionsListModel } from "../../../../modules/admin-modules/models/Actions";

const Questions = ({ examData, selectedData, isAdd }) => {
  const [values, setValues] = useState([]);

  const dispatch = useDispatch();
  const data = isAdd
    ? examData
    : {
        chapter_id: selectedData?.chapters?.[0]?.id,
        exam: selectedData?.id,
        question_number: selectedData?.question_number,
      };
  const questionsBank = useSelector((state) => state.exams.examQuestions);
  const isLoading = useSelector((state) => state.exams.isLoading);
  const openModel = useSelector((state) => state.model.isOpen26);

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        اختر الاسئلة
      </Heading>
    </Box>
  );

  const closeFunc = () => {
    dispatch(closeQuestionsListModel());
  };

  useEffect(() => {
    if (data?.chapter_id && openModel)
      dispatch(getAllExamQuestionsRequest(data?.chapter_id));
  }, [data?.chapter_id, openModel]);

  useEffect(() => {
    if (values?.length > data?.question_number)
      toast.error(`you should choose ${data?.question_number} Questions only`);
  }, [values]);
  const handelSave = async () => {
    if (values?.length <= data?.question_number) {
      let formData = new FormData();
      formData.append("exam_id", data?.exam);
      formData.append("count", data?.question_number);

      await values?.map((val, index) => {
        formData.append(`questions[${index}]`, val);
      });
      const action = () => {
        dispatch(closeQuestionsListModel());
      };
      dispatch(createExamQuestionsRequest(formData, action));
    } else {
      toast.error(`you should choose ${data?.question_number} Questions only`);
    }
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openModel}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handelSave}
    >
      <CheckboxGroup colorScheme="green" onChange={(e) => setValues(e)}>
        <Stack spacing={[1, 5]} direction={"column"}>
          {questionsBank?.map((data, index) => {
            return (
              <Checkbox value={data?.id?.toString()} key={index}>
                <div dangerouslySetInnerHTML={{ __html: data?.question }} />
              </Checkbox>
            );
          })}
        </Stack>
      </CheckboxGroup>
    </MainModel>
  );
};

export default Questions;
