import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { withTranslation } from "react-i18next";
import { GiEarthAmerica } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import MobileNavbar from "../../public/Layout/mobileNavbar";

const Navbar = ({ color, register, t, i18n, type }) => {
  const navigate = useNavigate();
  const routes = [
    { label: t("components.home_navbar.contact_us"), link: "#callCenter" },
    { label: t("components.home_navbar.subjects"), link: "#subjects" },
    { label: t("components.home_navbar.teachers"), link: "#teachers" },
    { label: t("components.home_navbar.home"), link: "/" },
  ];

  return (
    <Box
      w="100%"
      position="fixed"
      zIndex="100"
      top="0"
      color={color}
      backdropBlur="lg"
      id="navbar"
      py="20px"
      bg="#e4e8ebd9"
    >
      <Container
        maxW={"container.xl"}
        w="100%"
        className="fixed top-0 left-0  z-50 w-full py-5 bg-[#e4e8ebd9] backdrop-blur-lg"
      >
        <HStack
          alignItems="center"
          p="5px"
          w="100%"
          justifyContent={"space-between"}
          textTransform={"capitalize"}
          h="50px"
        >
          <Flex
            display={{ base: "none", lg: "flex" }}
            visibility={register ? "visible" : "hidden"}
            flexDir="row"
            w="100%"
            gap={5}
            alignItems="center"
          >
            <Button
              onClick={() => navigate("/login")}
              borderColor="main_2"
              bg="main_2"
              textTransform={"capitalize"}
              color="white"
            >
              {t("login")}{" "}
            </Button>

            <IconButton
              icon={<GiEarthAmerica />}
              borderColor="main_2"
              color="main_2"
              variant="outline"
              onClick={() => {
                const lang = localStorage.getItem("lang");

                if (lang === "ar") {
                  localStorage.setItem("lang", "en");
                } else {
                  localStorage.setItem("lang", "ar");
                }
                window.location.reload();
              }}
            />
            {routes?.map((elem, index) => {
              return (
                <a key={index} href={elem.link} style={{ color: "black" }}>
                  {elem?.label}
                </a>
              );
            })}
          </Flex>

          <Box display={{ base: "block", lg: "none" }}>
            <MobileNavbar />
          </Box>
          <Box>
            <Image
              src={require("../../../assets/logo.png")}
              w="200px"
              id="logo"
              h="auto"
              mx="-10px"
              onClick={() => navigate("/")}
            />
          </Box>
        </HStack>
      </Container>
    </Box>
  );
};

export default withTranslation()(Navbar);
