import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { BiLowVision, BiShow } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PublicLayout from "../public/Layout/layout";
const CirclesLoginForm = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [inputType, setInputType] = useState("password");

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
      width: "100%",
      padding: "20px",
      objectFit: "contain",
      borderRadius: "10px",
      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
      border: "solid 1px #010038",
      backgroundColor: "#fff",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  const validateUserName = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  const validatePassword = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  const validateSubject = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  return (
    <PublicLayout color="main_1" bg="white" register={false}>
      <Flex
        flexDirection="column"
        alignItems="center"
        my="50px"
        w="100%"
        h="100%"
      >
        <Formik
          initialValues={{
            phone: "",
            password: "",
          }}
          onSubmit={async (values) => {
            let formData = new FormData();
            formData.append("phone", values.phone);
            formData.append("password", values.password);

            const payload = await axios.post(
              "https://api.asquera.com/api/circles/users/login",
              formData
            );
            if (payload.data.state === "success") {
              toast.success("Logged in successfully");
            } else {
              toast.error(payload.data.errors?.join(","));
            }

            window.localStorage.setItem(
              "token",
              payload.data.data.access_token
            );

            setTimeout(() => {
              window.location.href = "/home";
            }, 3000);
          }}
        >
          {({ setValues, errors, touched }) => (
            <Form
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flex
                flexDirection={"column"}
                sx={{ width: { base: "90%", md: "40%" } }}
              >
                <Heading textAlign={"center"} mb="10px">
                  Login
                </Heading>
                <Box w="100%">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="phone"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Phone
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      width: "100%",
                      padding: "10px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                    }}
                    name="phone"
                    validate={validateUserName}
                  />
                  {errors.phone && touched.phone && (
                    <Text color="red">{errors.phone}</Text>
                  )}
                </Box>
                <Box mt="35px" position={"relative"} w="100%">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="password"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Password
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      padding: "10px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                    name="password"
                    validate={validatePassword}
                    type={inputType}
                  />

                  <Icon
                    position={"absolute"}
                    right="10px"
                    top="50px"
                    fontSize="33px"
                    color="main_1"
                    as={inputType == "password" ? BiShow : BiLowVision}
                    onClick={() =>
                      inputType == "password"
                        ? setInputType("text")
                        : setInputType("password")
                    }
                  />
                  {errors.password && touched.password && (
                    <Text color="red" w="180px" textAlign="justify">
                      {errors.password}
                    </Text>
                  )}
                </Box>
                <Button
                  mt="40px"
                  type="submit"
                  w="250px"
                  p="22px"
                  alignSelf={"center"}
                  isLoading={isLoading}
                  bg="main_1"
                  color="white"
                >
                  Login
                </Button>

                <Text textAlign="center" mt="20px">
                  Don't have an account?{" "}
                  <a
                    href="/signup"
                    style={{ color: "blue", textDecoration: "underline" }}
                  >
                    Sign Up
                  </a>
                </Text>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </PublicLayout>
  );
};

export default CirclesLoginForm;
