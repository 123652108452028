import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import YouTube from "react-youtube";
import { closeExamHintkModel } from "../../../modules/admin-modules/models/Actions";

const ExamHint = ({ videoId, model_answer_image }) => {
  const isOpen = useSelector((state) => state.model.isOpen30);
  const dispatch = useDispatch();
  const baseUrl = "https://asquera.com/asquera";
  const opts = {
    height: "300",
    width: "300",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const handelClose = () => {
    dispatch(closeExamHintkModel());
  };
  return (
    <Modal
      isCentered
      onClose={handelClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody
          p={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <>
            {model_answer_image != "null" && (
              <Image src={`${baseUrl}/${model_answer_image}`} w={"100%"} />
            )}
            {videoId && (
              <YouTube videoId={videoId} opts={opts} onReady={onReady} />
            )}
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExamHint;
