import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FcApprove, FcDisapprove } from "react-icons/fc";
import { MdOutlineLogout, MdWarningAmber } from "react-icons/md";
import { RiFileEditLine, RiForbid2Line, RiQrScan2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import {
  openCreateStudent,
  openStudentReportModel,
  openUserQRCodeModel,
} from "../../../modules/admin-modules/models/Actions";
import {
  approvedStudentsListRequest,
  approveStudentRequest,
  clearSessionRequest,
  deleteStudentRequest,
  rejectStudentRequest,
  restrictStudentRequest,
  studentsRequestsListRequest,
} from "../../../modules/admin-modules/students/Actions";

const CirclesStudentsTable = ({ setAdd, setSelectedData, searchTerm }) => {
  const approvedStudents = useSelector(
    (state) => state.students.approvedStudents
  );
  const pendeingStudents = useSelector(
    (state) => state.students.pendeingStudents
  );
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.students.isLoading);
  const count = useSelector((state) => state.students.count);
  const isRestrict = useSelector((state) => state.students.isRestrict);
  const isSuspend = useSelector((state) => state.students.isSuspend);

  const [offset, setPage] = useState(0);
  const [tab, setTab] = useState("approve");
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const studentsList = tab === "approve" ? approvedStudents : pendeingStudents;

  useEffect(() => {
    const timeId = setTimeout(() => {
      tab === "approve"
        ? dispatch(approvedStudentsListRequest(pageNumber + 1, searchTerm))
        : dispatch(studentsRequestsListRequest(pageNumber + 1, searchTerm));
    }, 200);

    return () => {
      clearTimeout(timeId);
    };
  }, [pageNumber, searchTerm, tab]);

  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };

  const PendingData = studentsList?.map((data, index) => {
    return {
      ...data,
      index: `#${index + 1}`,
      id: data?.id,
      student_name: (
        <Text
          cursor="pointer"
          onClick={() => dispatch(openStudentReportModel())}
        >
          {data?.name}
        </Text>
      ),
      group_name: data?.group?.name,
      phone: data?.phone,
      profile_url: (
        <Button
          variant="link"
          color="blue.500"
          onClick={() =>
            window.open("https://asquera.com/asquera/" + data?.profile_url, "_blank")
          }
        >
          View Profile
        </Button>
      ),
      profile_url2: (
        <Button
          variant="link"
          color="blue.500"
          onClick={() =>
            window.open("https://asquera.com/asquera/" + data?.profile_url2, "_blank")
          }
        >
          View Profile 2
        </Button>
      ),
      approve: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="40px"
          icon={<FcApprove style={{ fontSize: "40px" }} />}
          onClick={() => dispatch(approveStudentRequest(data?.id))}
          isLoading={selected.id == data?.id ? isSuspend : false}
        />
      ),
      reject: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<FcDisapprove style={{ fontSize: "40px" }} />}
          onClick={() => dispatch(rejectStudentRequest(data?.id))}
          isLoading={selected.id == data?.id ? isRestrict : false}
        />
      ),
    };
  });
  const pendingColumns = [
    {
      Header: "رقم الطالب",
      accessor: "index",
    },
    {
      Header: "اسم الطالب",
      accessor: "student_name",
    },
    {
      Header: " رقم  الطالب",
      accessor: "phone",
    },
    {
      Header: " صورة اثبات الشخصية",
      accessor: "profile_url",
    },
    {
      Header: " صورة اثبات الشخصية 2",
      accessor: "profile_url2",
    },
    {
      Header: " Approve",
      accessor: "approve",
    },
    {
      Header: " Reject",
      accessor: "reject",
    },
  ];

  const Data = studentsList?.map((data, index) => {
    return {
      ...data,
      index: `#${index + 1}`,
      id: data?.id,
      student_name: (
        <Text
          cursor="pointer"
          onClick={() => dispatch(openStudentReportModel())}
        >
          {data?.name}
        </Text>
      ),
      group_name: data?.group?.name,
      phone: data?.phone,
      code: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiQrScan2Line />}
          onClick={() => dispatch(openUserQRCodeModel())}
        />
      ),

      delete: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={
            <MdWarningAmber
              color={data?.deleted_at == null ? "red" : "green"}
            />
          }
          onClick={() => dispatch(deleteStudentRequest(data?.id))}
          isLoading={selected.id == data?.id ? isSuspend : false}
        />
      ),
      restrict: (
        <IconButton
          variant="outline"
          color="gray.600"
          border="0"
          aria-label="Call Sage"
          fontSize="20px"
          isLoading={selected.id == data?.id ? isRestrict : false}
          icon={<RiForbid2Line color={data?.restrict == 0 ? "red" : "green"} />}
          onClick={() => {
            const formData = new FormData();
            formData.append("_method", "PUT");
            data?.restrict == 0
              ? formData.append("restrict", 0)
              : formData.append("restrict", 1);
            dispatch(
              restrictStudentRequest({
                id: data?.id,
                formData: formData,
              })
            );
          }}
        />
      ),
      clearSession: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<MdOutlineLogout />}
          onClick={() => dispatch(clearSessionRequest(data?.id))}
        />
      ),
      edit: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiFileEditLine />}
          onClick={() => {
            setAdd(false);
            dispatch(openCreateStudent());
          }}
        />
      ),
    };
  });
  const columns = [
    {
      Header: "رقم الطالب",
      accessor: "index",
    },
    {
      Header: "اسم الطالب",
      accessor: "student_name",
    },
    {
      Header: "Asquera code",
      accessor: "user_name",
    },
    {
      Header: " المجموعة",
      accessor: "group_name",
    },
    {
      Header: " Qr Code",
      accessor: "code",
    },
    {
      Header: " رقم  الطالب",
      accessor: "phone",
    },
    {
      Header: " ايقاف",
      accessor: "delete",
    },
    {
      Header: " Clear Session",
      accessor: "clearSession",
    },
    {
      Header: "Restrict",
      accessor: "restrict",
    },
    {
      Header: "تعديل",
      accessor: "edit",
    },
  ];

  const actions = (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<RiFileEditLine />}
        size="sm"
        fontSize="20px"
        variant="outline"
        border="none"
      />
      <MenuList>
        <MenuItem
          onClick={() => {
            setAdd(false);
            dispatch(openCreateStudent());
          }}
        >
          تعديل
        </MenuItem>
      </MenuList>
    </Menu>
  );

  return (
    <>
      <Stack
        flexDirection="row"
        gap="10px"
        alignItems="center"
        justifyContent="center"
        my="10"
      >
        <Button
          onClick={() => setTab("pending")}
          style={{
            background: tab == "pending" ? "green" : "",
            color: tab == "pending" ? "white" : "",
          }}
        >
          Pending
        </Button>
        <Button
          mt="0 !important"
          onClick={() => setTab("approve")}
          style={{
            background: tab == "approve" ? "green" : "",
            color: tab == "approve" ? "white" : "",
          }}
        >
          Approved
        </Button>
      </Stack>
      <CTable
        selectedData={viewData}
        Columns={tab === "approve" ? columns : pendingColumns}
        Data={tab === "approve" ? Data : PendingData}
        path={"openModel"}
        Actions={actions}
        Title={``}
        subTitle=""
        btnTitle=""
        placeHolder=""
        noSearchBar={true}
        noFilter={true}
        footerBtnTitle
        filterList
        addButton
        addButtonTitle=""
        setPage={setPage}
        setPerPage={setPerPage}
        currentpage={pageNumber}
        setPageNumber={setPageNumber}
        noSecondSearchBar={true}
        isLoading={isLoading}
        perPage={size}
        totalPage={Math.ceil(count / 50)}
      />
    </>
  );
};

export default CirclesStudentsTable;
