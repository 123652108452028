import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import TeacherSidenavbar from "./teacherSidenavbar";
import SuperAdminSidenavbar from "./superAdminSidenavbar";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiOutlineMenu } from "react-icons/ai";
import LogoutMenu from "../Layout/logout-menu";
export default function MobileSidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const userData = useSelector((state) => state.user.userData);

  return (
    <>
      <Box
        position="absolute"
        left="10px"
        top="45px"
        display={{ base: "flex", md: "none" }}
        background="white"
        height="50px"
        zIndex="100"
        alignItems="center"
        justifyContent="center"
      >
        <LogoutMenu />

        <Button ref={btnRef} onClick={onOpen} mx={4}>
          <AiOutlineMenu />
        </Button>
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent dir="rtl" p="0">
          <IconButton
            icon={<IoIosArrowBack fontSize="25px" />}
            onClick={onClose}
            background="transparent"
            alignSelf="end"
          />

          <DrawerBody p="0">
            {userData?.role?.length > 0 ? (
              userData?.role[0]?.name == "Teacher" ? (
                <TeacherSidenavbar />
              ) : (
                <SuperAdminSidenavbar />
              )
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
