import Types from "./Types";

const INITIAL_STATE = {
  user: null,
  token: null,
  isLoading: false,
  years: [],
  faculties: [],
};

export default function auth(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.LOGIN_USER_SUCCESS: {
      window.localStorage.setItem("token", payload.user.data.data.access_token);
      // Cookie.set('token', payload.user.data.data.access_token, { expires: 90 });
      return {
        ...state,
        token: payload.user.data.data.access_token,
      };
    }

    case Types.SIGNUP_USER_SUCCESS: {
      return {
        ...state,
      };
    }

    case Types.YEARS_SUCCESS: {
      return {
        ...state,
        years: payload?.data,
      };
    }

    case Types.FACULTIES_SUCCESS: {
      return {
        ...state,
        faculties: payload?.data,
      };
    }
    case Types.LOGOUT_USER_SUCCESS: {
      // Cookie.remove('token');
      window.localStorage.clear();
      return {
        ...state,
        user: null,
        token: null,
      };
    }

    // Misc
    case Types.SET_AUTH_IS_LOADING: {
      const isLoading = payload;
      return {
        ...state,
        isLoading: isLoading,
      };
    }

    default: {
      return state;
    }
  }
}
