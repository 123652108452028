import { Circle, HStack } from "@chakra-ui/react";
import React from "react";

const ExamEllipses = ({
  examCorrection,
  answeredQ,
  questionsCount,
  setSelectedQuestionIdx,
  selectedQuestionIdx,
}) => {
  const path = window.location.pathname;
  const isPath = () => {
    if (path.includes("exam-result")) return true;
    if (path.includes("study")) return true;
  };
  const RightAnswerId = (index) => {
    if (path.includes("study")) {
      return examCorrection?.[index]?.answers?.find(
        (data) => data?.right_answer == 1
      )?.id;
    } else
      return examCorrection?.questions?.[index]?.answers?.find(
        (data) => data?.right_answer == 1
      )?.id;
  };

  return (
    <HStack flexWrap="wrap">
      {[...Array(questionsCount)]?.map((data, index) => {
        return (
          <Circle
            size="40px"
            cursor="pointer"
            bg={
              isPath(path)
                ? examCorrection?.answers
                  ? examCorrection?.answers[index]?.student_answer_id ==
                    RightAnswerId(index)
                    ? "green.400"
                    : "red.500"
                  : examCorrection?.[index]?.student_answers
                      ?.student_answer_id == RightAnswerId(index)
                  ? "green.400"
                  : !examCorrection?.[index]?.student_answers
                  ? "main_1"
                  : "red.500"
                : selectedQuestionIdx == index
                ? "white"
                : answeredQ?.includes(index.toString())
                ? "gray.400"
                : "red.500"
            }
            color={
              isPath(path)
                ? "white"
                : selectedQuestionIdx == index
                ? "main_1"
                : "white"
            }
            border="1px solid"
            borderColor={
              selectedQuestionIdx == index
                ? "main_1"
                : answeredQ?.includes(index.toString())
                ? "green.400"
                : "red.300"
            }
            onClick={() => setSelectedQuestionIdx(index)}
          >
            {index + 1}
          </Circle>
        );
      })}
    </HStack>
  );
};

export default ExamEllipses;
