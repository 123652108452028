export default {
  //get all GROUPs
  GET_GROUPS_REQUEST: "GET_GROUPS_REQUEST",
  GET_GROUPS_SUCCESS: "GET_GROUPS_SUCCESS",
  GET_GROUPS_FAIL: "GET_GROUPS_FAIL",

  //edit GROUPs
  EDIT_GROUP_REQUEST: "EDIT_GROUP_REQUEST",
  EDIT_GROUP_SUCCESS: "EDIT_GROUP_SUCCESS",
  EDIT_GROUP_FAIL: "EDIT_GROUP_FAIL",

  //create GROUP
  CREATE_GROUP_REQUEST: "CREATE_GROUP_REQUEST",
  CREATE_GROUP_SUCCESS: "CREATE_GROUP_SUCCESS",
  CREATE_GROUP_FAIL: "CREATE_GROUP_FAIL",

  DELETE_GROUP_REQUEST: "DELETE_GROUP_REQUEST",
  DELETE_GROUP_SUCCESS: "DELETE_GROUP_SUCCESS",
  DELETE_GROUP_FAIL: "DELETE_GROUP_FAIL",

  GET_GROUP_REQUEST: "GET_GROUP_REQUEST",
  GET_GROUP_SUCCESS: "GET_GROUP_SUCCESS",
  GET_GROUP_FAIL: "GET_GROUP_FAIL",

  GET_PERFORMANCE_GROUPS_REQUEST: "GET_PERFORMANCE_GROUPS_REQUEST",
  GET_PERFORMANCE_GROUPS_SUCCESS: "GET_PERFORMANCE_GROUPS_SUCCESS",
  GET_PERFORMANCE_GROUPS_FAIL: "GET_PERFORMANCE_GROUPS_FAIL",
  //loading
  ADD_GROUPS_LOADING: "ADD_GROUPS_LOADING",
};
