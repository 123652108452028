import {
  Box,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MainModel from "../../../../components/Models/main-model";
import { closeShowStudentDataModel } from "../../../../modules/admin-modules/models/Actions";

const ShowPassword = ({ data }) => {
  const openStudentData = useSelector((state) => state.model.isOpen9);
  const dispatch = useDispatch();
  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        بيانات الطالب
      </Heading>
    </Box>
  );

  const closeFunc = () => {
    dispatch(closeShowStudentDataModel());
  };
  return (
    <MainModel header={header} closeFunc={closeFunc} openVar={openStudentData}>
      <TableContainer>
        <Table variant="striped" dir="rtl">
          <Tbody>
            <Tr>
              <Td>اسم الطالب</Td>
              <Td>{data?.name}</Td>
            </Tr>
            <Tr>
              <Td>كلمة المرور</Td>
              <Td>{data?.password}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </MainModel>
  );
};

export default ShowPassword;
