import axios from "axios";

export const onLanguageHandle = (lang, i18n) => {
  i18n.changeLanguage(lang);
  if (lang === "ar") {
    localStorage.setItem("language", "ar");
    axios.defaults.headers.common["Accept-Language"] = "ar";
  } else {
    localStorage.setItem("language", "en");
    axios.defaults.headers.common["Accept-Language"] = "en";
  }

  window.location.reload();
};
