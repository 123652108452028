import React, { useState } from "react";
import { Container, Grid, Flex, Text, Image, Stack } from "@chakra-ui/react";
import reviews from "../../assets/review-1.png";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
const StudentComp = ({ t, data }) => {
  const [recordesData, setRecordesData] = useState(
    require("../../staticData/recordes.json")
  );

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <HiArrowRight
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#f37335",
          fontSize: "30px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <HiArrowLeft
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#f37335",
          fontSize: "30px",
        }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    className: "teacher-slider",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container maxW={"container.xl"} mt="200px" mb="100px">
      <Flex
        alignItems={"center"}
        justifyContent="center"
        flexDirection={"column"}
        id="resources"
        gap={12}
      >
        <Text fontSize="32px" fontWeight="600" textTransform={"uppercase"}>
          {t(`public_home.outstandingStudents`)}
        </Text>

        <Slider {...settings} className="slider teacher-slider">
          {data?.topStudents?.map((val, idx) => {
            return (
              <Stack
                display="flex"
                my="20px"
                w="300px"
                key={idx}
                flexDirection="column"
                gap={2}
                align="center"
                justifyContent="center"
              >
                <Image src={reviews} w="100%" />
              </Stack>
            );
          })}
        </Slider>
      </Flex>
    </Container>
  );
};

export default withTranslation()(StudentComp);
