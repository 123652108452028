import img1 from "../assets/teacher-data/abdoufarag.jpeg";
import img4 from "../assets/teacher-data/ezzat.jpeg";
import img3 from "../assets/teacher-data/ibrahim-elmahdy.jpeg";
import img5 from "../assets/teacher-data/Mo.jpg";
import img2 from "../assets/teacher-data/mohamednasr.jpeg";
import img6 from "../assets/teacher-data/sara.jpg";
import img7 from "../assets/teacher-data/nassar.jpg";
import img8 from "../assets/teacher-data/doaa-adel.jpg";
import img9 from "../assets/teacher-data/mathophobia.png";
import img10 from "../assets/teacher-data/omar.jpg";

export const teacherData = [
  {
    key: "1",
    name: "Monsieur Abdou Farag",
    image: img1,
    subject: "French",
    subjectAr: "اللغة الفرنسية",
  },
  {
    key: "2",
    name: "Herr Mohamed Nasr",
    image: img2,
    subject: "Germany",
    subjectAr: "اللغة الالمانية",
  },
  {
    key: "3",
    name: "Mr. Ibrahim Elmahdy",
    image: img3,
    subject: "Math",
    subjectAr: "الرياضيات",
  },
  {
    key: "4",
    name: "Mr. Mohamed Ezzat",
    image: img4,
    subject: "Physics",
    subjectAr: "الفيزياء",
  },
  {
    key: "5",
    name: "Mr. Mohamed Ali",
    image: img5,
    subject: "History",
    subjectAr: " تاريخ",
  },
  {
    key: "6",
    name: "Sig. Sara",
    image: img6,
    subject: "Italian",
    subjectAr: "اللغة الايطالية",
  },
  {
    key: "7",
    name: "Mr. Mahmoud Nassar",
    image: img7,
    subject: "English",
  },
  {
    key: "8",
    name: "Mrs. Doaa Adel",
    image: img8,
    subject: "Biology",
    subjectAr: "الاحياء",
  },
  {
    key: "9",
    name: "Mathophobia Vaccine",
    image: img9,
    subject: "Math",
    subjectAr: "الرياضيات",
  },
  {
    key: "10",
    name: "Mr. Omar Soliman",
    image: img10,
    subject: "Chemistry",
    subjectAr: "الكيمياء",
  },
];
