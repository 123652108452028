import {
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";

const SearchBar = ({ setAdd, handelAcceptAll }) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.redoRequests.count);
  const isLoading = useSelector((state) => state.redoRequests.isLoading);

  return (
    <VStack w="100%" my={{ base: "50px", md: "30px" }}>
      <Flex
        alignItems="center"
        w="100%"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Text
          fontSize="24px"
          fontWeight="600"
          color="main_1"
          width={{ base: "100%", md: "20%" }}
          mb={{ base: "10px", md: 0 }}
        >
          طلبات اعادة الاختبارات
        </Text>

        <Flex alignItems="center" flexWrap="wrap" gap={2}>
          <Button onClick={handelAcceptAll} isLoading={isLoading}>
            Accept All
          </Button>
          <Box display={{ base: "none", md: "block" }}>
            <LogoutMenu />
          </Box>
        </Flex>
      </Flex>
      <Text w="100%" fontSize="20px" textAlign="right">
        {" "}
        عدد الطلبات: {count}
      </Text>
    </VStack>
  );
};

export default SearchBar;
