import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { RiAddFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { openCreateLectureModel } from "../../../modules/admin-modules/models/Actions";

const SearchBar = ({ setAdd }) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.lectures.count);

  return (
    <VStack w="100%" my={{ base: "50px", md: "30px" }}>
      <Flex
        alignItems="center"
        w="100%"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Text
          fontSize="24px"
          fontWeight="600"
          color="main_1"
          width={{ base: "100%", md: "20%" }}
          mb={{ base: "10px", md: 0 }}
        >
          الحصص
        </Text>

        <Flex alignItems="center" flexWrap="wrap" gap={2}>
          <Button
            borderRadius="16px"
            mx="7px"
            leftIcon={<RiAddFill />}
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openCreateLectureModel());
            }}
          >
            إضافة حصة
          </Button>

          <Box display={{ base: "none", md: "block" }}>
            <LogoutMenu />
          </Box>
        </Flex>
      </Flex>
      <Text w="100%" fontSize="20px" textAlign="right">
        عدد الحصص: {count}
      </Text>
    </VStack>
  );
};

export default SearchBar;
