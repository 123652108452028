import {
  Box,
  chakra,
  Flex,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";

const ExamQuestions = ({ data, answers, setAnswers, selectedQuestionIdx }) => {
  const baseUrl = "https://asquera.com/asquera";
  const [loading, setLoading] = useState(false);

  return (
    <VStack
      w="100%"
      spacing="20px"
      h="100%"
      onSelect={(e) => e.preventDefault()}
    >
      {/* question */}
      <Stack
        w="100%"
        flexDirection={{ base: "column", lg: "row" }}
        spacing={{ base: "5%", lg: "0" }}
        alignItems="flex-start"
        p={4}
        border="1px solid black"
        borderRadius="10px"
      >
        <VStack w={{ base: "100%" }} alignItems={"center"}>
          <Box textAlign="center" w="100%">
            <chakra.span
              fontSize="18px"
              fontWeight="600"
              textDecoration="underline"
            >
              Comprehension
            </chakra.span>{" "}
            <Text textAlign="center">{data?.comprehension}</Text>
          </Box>
          <Box
            textAlign="center"
            w="100%"
            onMouseDown={(e) => e.preventDefault()}
          >
            <chakra.span
              fontSize="18px"
              fontWeight="600"
              textDecoration="underline"
            >
              Question :
            </chakra.span>{" "}
            <Text textAlign="center">
              <div dangerouslySetInnerHTML={{ __html: data?.question }} />
            </Text>
          </Box>
        </VStack>

        {data?.image && (
          <Image
            src={`${baseUrl}/${data?.image}`}
            w={{ base: "100%", lg: "60%" }}
          />
        )}
      </Stack>

      {/* answers */}

      <Stack
        role="group"
        aria-labelledby="my-radio-group"
        spacing={0}
        alignItems="flex-start"
        direction={{ base: "column", lg: "row" }}
        flexWrap="wrap"
        w="100%"
      >
        {data?.answers?.map((val, index) => {
          return (
            <Flex
              w={{ base: "100%", lg: "35%" }}
              my="20px !important"
              alignItems="center"
            >
              {/* {!loading && ( */}
              <label
                htmlFor={`answer_${index}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <input
                  type="radio"
                  key={index}
                  value={val?.id}
                  name="answer"
                  id={`answer_${index}`}
                  style={{
                    width: "20px",
                  }}
                  onClick={(e) => {
                    setLoading(true);
                    const formData = {
                      question_id: data?.id,
                      answer_id: e?.target?.value,
                    };
                    let newAnswers = answers;
                    answers[selectedQuestionIdx] = formData;
                    setAnswers(() => newAnswers);
                    setTimeout(() => {
                      setLoading(false);
                    }, 300);
                  }}
                  checked={
                    answers[selectedQuestionIdx]?.answer_id == val?.id
                      ? true
                      : false
                  }
                />
                <Text mx="5px" fontSize="20px" mt="-5px">
                  {val?.answer}
                </Text>
              </label>
              {/* // )} */}
            </Flex>
          );
        })}
      </Stack>
    </VStack>
  );
};

export default ExamQuestions;
