import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { RiAddFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { openCreateChapterModel } from "../../../modules/admin-modules/models/Actions";

const SearchBar = ({ setAdd }) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.chapters.count);

  return (
    <VStack w="100%" my={{ base: "50px", md: "30px" }}>
      <Flex
        alignItems="center"
        w="100%"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Text
          fontSize="24px"
          fontWeight="600"
          color="main_1"
          width={{ base: "100%", md: "20%" }}
          mb={{ base: "10px", md: 0 }}
        >
          الفصول
        </Text>

        <Flex alignItems="center" flexWrap="wrap" gap={2}>
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            mx="8px"
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openCreateChapterModel());
            }}
          >
            إضافة فصل
          </Button>
          <Box display={{ base: "none", md: "block" }}>
            <LogoutMenu />
          </Box>
        </Flex>
      </Flex>
      <Text w="100%" fontSize="20px" textAlign="right">
        {" "}
        عدد الفصول: {count}
      </Text>

      {/* <HStack
        w="100%"
        justifyContent="space-between"
        bg="#f5f5f5"
        mt="40px !important"
        p="10px"
        mb="-30px !important"
      >
        <HStack spacing="5px">
          <InputGroup w={{base:'100%' , md:"400px"}}>
            <InputLeftElement
              pointerEvents="none"
              children={<RiSearch2Fill color="main_1" fontSize="20px" />}
            />
            <Input
              type="text"
              placeholder="عنوان الفصل"
              borderRadius="16px"
              bg="white"
            />
          </InputGroup>
        </HStack>
      </HStack> */}
    </VStack>
  );
};

export default SearchBar;
