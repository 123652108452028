import { Box, Container, Flex, Icon, Image, Text } from "@chakra-ui/react";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  RiFacebookBoxFill,
  RiInstagramFill,
  RiTelegramFill,
  RiYoutubeFill,
} from "react-icons/ri";
const Footer = ({ t }) => {
  return (
    <Box
      bg="main_1"
      color="white"
      w="100%"
      overflow="hidden"
      p={4}
      alignSelf="end"
      position="absolute"
      bottom="-40px"
    >
      <Container
        maxWidth={"container.xl"}
        bg="#e4e8eb4d"
        borderRadius="20px"
        p={4}
      >
        <Flex
          justifyContent={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
        >
          <Flex flexDirection={"column"} w={{ base: "100%", md: "45%" }}>
            <Image src={require("../../../assets/logo.png")} w="200px" />{" "}
          </Flex>
          <Flex direction="column">
            <Text fontWeight="500" color="white">
              تابعنا علي منصات التواصل الإجتماعي
            </Text>

            <Flex w={{ base: "100%", md: "15%" }} gap={5} mt={2} align="start">
              <a
                href="https://www.facebook.com/asqueraeg"
                target="_blank"
                rel="noreferrer"
              >
                <Icon as={RiFacebookBoxFill} fontSize="45px" color="white" />
              </a>
              <a
                href="https://www.youtube.com/@Asquera"
                target="_blank"
                rel="noreferrer"
              >
                <Icon as={RiYoutubeFill} fontSize="45px" color="white" />
              </a>
              <a
                href="https://www.instagram.com/asqueraeg/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon as={RiInstagramFill} fontSize="45px" color="white" />
              </a>
              <a
                href="https://web.telegram.org/a/#7162134760"
                target="_blank"
                rel="noreferrer"
              >
                <Icon as={RiTelegramFill} fontSize="45px" color="white" />
              </a>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default withTranslation()(Footer);
