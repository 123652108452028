import { Button, Container, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import reviews from "../../assets/review-1.png";
import { motion, Variants } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";

const TeacherData = ({ data }) => {
  const navigate = useNavigate();
  const cardVariants = {
    offscreen: {
      y: 300,
      rotate: -10,
    },
    onscreen: {
      y: 50,
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };
  return (
    <Container maxW={"container.xl"} mb="100px">
      <Stack
        flexDirection={{ base: "column", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          flexDirection={{ base: "column" }}
          alignItems="center"
          justifyContent="space-between"
          width={{ base: "100%", md: "50%" }}
        >
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          >
            <motion.div variants={cardVariants}>
              <Image
                src={data?.image}
                w="100%"
                width="400px"
                borderRadius="50%"
              />
            </motion.div>
          </motion.div>
        </Stack>

        <Stack
          width={{ base: "100%", md: "50%" }}
          gap={2}
          alignSelf="center"
          pt="50px"
          alignItems={{ base: "center", md: "start" }}
        >
          <Text
            fontSize="40px"
            sx={{
              fontSize: { base: "28px", md: "40px" },
              color: "black",
              fontWeight: "600",
            }}
          >
            {data?.name}
          </Text>
          <Text
            fontSize={{ base: "26px", md: "32px" }}
            fontWeight="400"
            textTransform={"uppercase"}
            width={{ base: "100%", md: "400px" }}
            textAlign={{ base: "center", md: "start" }}
          >
            Technology testing talent
          </Text>

          <Button
            onClick={() => navigate("/login")}
            sx={{ width: { base: "50%", md: "35%" } }}
            bg="main_1"
            w="100%"
            textTransform={"capitalize"}
            color="white"
          >
            login
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default TeacherData;
