import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdOutlineLogout, MdWarningAmber } from "react-icons/md";
import { RiFileEditLine, RiForbid2Line, RiQrScan2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import {
  openCreateStudent,
  openStudentReportModel,
  openUserQRCodeModel,
} from "../../../modules/admin-modules/models/Actions";
import {
  clearSessionRequest,
  deleteStudentRequest,
  getAllStudentsRequest,
  restrictStudentRequest,
} from "../../../modules/admin-modules/students/Actions";

const StudentsTable = ({ setAdd, setSelectedData, searchTerm }) => {
  const students = useSelector((state) => state.students.students);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.students.isLoading);
  const count = useSelector((state) => state.students.count);

  const isRestrict = useSelector((state) => state.students.isRestrict);
  const isSuspend = useSelector((state) => state.students.isSuspend);
  const [offset, setPage] = useState(0);

  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });

  useEffect(() => {
    const timeId = setTimeout(() => {
      dispatch(getAllStudentsRequest(pageNumber + 1, searchTerm));
    }, 200);

    return () => {
      clearTimeout(timeId);
    };
  }, [pageNumber, searchTerm]);

  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const Data = students?.map((data, index) => {
    return {
      ...data,
      index: `#${index + 1}`,
      id: data?.id,
      student_name: (
        <Text
          cursor="pointer"
          onClick={() => dispatch(openStudentReportModel())}
        >
          {data?.name}
        </Text>
      ),
      group_name: data?.group?.name,
      phone: data?.phone,
      code: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiQrScan2Line />}
          onClick={() => dispatch(openUserQRCodeModel())}
        />
      ),

      delete: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={
            <MdWarningAmber
              color={data?.deleted_at == null ? "red" : "green"}
            />
          }
          onClick={() => dispatch(deleteStudentRequest(data?.id))}
          isLoading={selected.id == data?.id ? isSuspend : false}
        />
      ),
      restrict: (
        <IconButton
          variant="outline"
          color="gray.600"
          border="0"
          aria-label="Call Sage"
          fontSize="20px"
          isLoading={selected.id == data?.id ? isRestrict : false}
          icon={<RiForbid2Line color={data?.restrict == 0 ? "red" : "green"} />}
          onClick={() => {
            const formData = new FormData();
            formData.append("_method", "PUT");
            data?.restrict == 0
              ? formData.append("restrict", 0)
              : formData.append("restrict", 1);
            dispatch(
              restrictStudentRequest({
                id: data?.id,
                formData: formData,
              })
            );
          }}
        />
      ),
      clearSession: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<MdOutlineLogout />}
          onClick={() => dispatch(clearSessionRequest(data?.id))}
        />
      ),
      edit: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiFileEditLine />}
          onClick={() => {
            setAdd(false);
            dispatch(openCreateStudent());
          }}
        />
      ),
    };
  });
  const columns = [
    {
      Header: "رقم الطالب",
      accessor: "index",
    },
    {
      Header: "اسم الطالب",
      accessor: "student_name",
    },
    {
      Header: "Asquera code",
      accessor: "user_name",
    },
    {
      Header: " المجموعة",
      accessor: "group_name",
    },
    {
      Header: " Qr Code",
      accessor: "code",
    },
    {
      Header: " رقم  الطالب",
      accessor: "phone",
    },
    {
      Header: " ايقاف",
      accessor: "delete",
    },
    {
      Header: " Clear Session",
      accessor: "clearSession",
    },
    {
      Header: "Restrict",
      accessor: "restrict",
    },
    {
      Header: "تعديل",
      accessor: "edit",
    },
  ];

  const actions = (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<RiFileEditLine />}
        size="sm"
        fontSize="20px"
        variant="outline"
        border="none"
      />
      <MenuList>
        <MenuItem
          onClick={() => {
            setAdd(false);
            dispatch(openCreateStudent());
          }}
        >
          تعديل
        </MenuItem>
      </MenuList>
    </Menu>
  );
  return (
    <CTable
      selectedData={viewData}
      Columns={columns}
      Data={Data}
      path={"openModel"}
      Actions={actions}
      Title={``}
      subTitle=""
      btnTitle=""
      placeHolder=""
      noSearchBar={true}
      noFilter={true}
      footerBtnTitle
      filterList
      addButton
      addButtonTitle=""
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={pageNumber}
      setPageNumber={setPageNumber}
      noSecondSearchBar={true}
      isLoading={isLoading}
      perPage={size}
      totalPage={Math.ceil(count / 50)}
    />
  );
};

export default StudentsTable;
