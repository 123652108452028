import {
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import {
  RiAddFill,
  RiFileExcel2Line,
  RiPrinterLine,
  RiSearch2Fill,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { openCreateLsson } from "../../../modules/admin-modules/models/Actions";

const SearchBar = ({ setAdd, setSelectedData }) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.lessons.count);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "white",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  return (
    <VStack w="100%" my={{ base: "50px", md: "30px" }}>
      <Flex
        alignItems="center"
        w="100%"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Text
          fontSize="24px"
          fontWeight="600"
          color="main_1"
          width={{ base: "100%", md: "20%" }}
          mb={{ base: "10px", md: 0 }}
        >
          المحتوي
        </Text>

        <Flex alignItems="center" flexWrap="wrap" gap={2}>
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openCreateLsson());
            }}
          >
            إضافة محتوى
          </Button>
          <Button
            borderColor="main_1"
            border="1px solid"
            mx="10px"
            borderRadius="16px"
            leftIcon={<RiPrinterLine />}
            color="main_1"
            bg="white"
            _focus={{ outline: "none" }}
          >
            طباعة المحتوى
          </Button>
          <Box display={{ base: "none", md: "block" }}>
            <LogoutMenu />
          </Box>
        </Flex>
      </Flex>
      <Text w="100%" fontSize="20px" textAlign="right">
        عدد الكورسات:{count}
      </Text>
    </VStack>
  );
};

export default SearchBar;
