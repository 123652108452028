import { Box, Button, Flex, FormLabel, Heading, Input } from "@chakra-ui/react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PublicLayout from "../public/Layout/layout";

const OtpForm = () => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");

  const { loading, error, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (message) {
      toast.success(message);
    }
  }, [error, message]);

  const handleOtpSubmit = (values) => {
    axios
      .post("https://api.asquera.com/api/circles/users/check-otp", {
        code: values.otp,
      })
      .then((response) => {
        toast.success("OTP verified successfully");

        // timeout to redirect to login page
        setTimeout(() => {
          window.location.href = "/circles/login";
        }, 5000);
      })
      .catch((error) => {
        toast.error("Invalid OTP");
      });
  };

  return (
    <PublicLayout>
      <Box
        bg="white"
        p={8}
        my={8}
        borderRadius="md"
        boxShadow="md"
        w={{ base: "100%", md: "50%" }}
        mx="auto"
      >
        <Heading size="lg" mb={6}>
          Verify OTP
        </Heading>
        <Formik
          initialValues={{
            otp: "",
          }}
          onSubmit={(values) => {
            handleOtpSubmit(values);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field name="otp">
                {({ field }) => (
                  <Flex direction="column" mb={4}>
                    <FormLabel htmlFor="otp">OTP</FormLabel>
                    <Input
                      {...field}
                      id="otp"
                      placeholder="Enter OTP"
                      type="text"
                    />
                  </Flex>
                )}
              </Field>
              <Button
                type="submit"
                bg="main_1"
                color={"white"}
                isLoading={isSubmitting}
              >
                Verify OTP
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </PublicLayout>
  );
};

export default OtpForm;
