import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddHomework from "../../../containers/admin/homework/models/add-homework";
import SearchBar from "../../../containers/admin/homework/searchbar";
import HomeworkTable from "../../../containers/admin/homework/table";
import { getAllHomeworksRequest } from "../../../modules/admin-modules/homework/Actions";

const Homeworks = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllHomeworksRequest());
  }, []);
  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddHomework
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
      <HomeworkTable setAdd={setAdd} setSelectedData={setSelectedData} />
    </Layout>
  );
};

export default Homeworks;
