import {
  Avatar,
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Layout from "../../../components/student/Layout/layout";
import { getSectionRequest } from "../../../modules/students-modules/sections/Actions";
const ChaptersExamsList = ({ t }) => {
  const baseUrl = "https://asquera.com/asquera";

  const { id } = useParams();

  const section = useSelector((state) => state.sections.section);
  const isLoading = useSelector((state) => state.sections.isLoading);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) dispatch(getSectionRequest(id));
  }, [id]);
  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start">
          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            {t("exams")}
          </Heading>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={6}
            w="100%"
          >
            {section?.map((chapter, idx) => {
              return (
                <Link
                  to={`/exam/${chapter?.id}?name=${chapter?.name}`}
                  key={idx}
                >
                  <Flex
                    flexDirection={"row"}
                    borderRadius="10px"
                    bg="#ededed"
                    gap={2}
                    p="10px"
                    cursor="pointer"
                    alignItems="center"
                  >
                    <Avatar
                      name={chapter?.name}
                      src={baseUrl + "/" + chapter?.logo}
                      borderRadius="8px"
                      size="lg"
                      background="transparent"
                    />
                    <Box>
                      <Text fontWeight="bold" fontSize="20px">
                        {chapter?.name}
                      </Text>
                      {/* <Text fontWeight="400" fontSize="18px">
                        {chapter?.videos_count} {t("exams")}
                      </Text> */}
                    </Box>
                  </Flex>
                </Link>
              );
            })}
          </Grid>
        </VStack>
      </Container>
    </Layout>
  );
};

export default withTranslation()(ChaptersExamsList);
