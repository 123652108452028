import { Stack } from "@chakra-ui/react";
import React from "react";
import Layout from "../../../components/student/Layout/layout";

const EmbeddedForm = () => {
  return (
    <Layout color="main_1" bg="white">
      <Stack alignItems="center" justifyContent="center" mt="100px !important">
        <iframe
          title="Google Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLScPq02-0k80QyR9CfBozVR1LUhk_MIARDWLmFPeGBO880-OvQ/viewform?embedded=true"
          width="540"
          height="500"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading...
        </iframe>
      </Stack>
    </Layout>
  );
};

export default EmbeddedForm;
