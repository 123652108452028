import {
  Container,
  Grid,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { TbBooks } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "../../../components/student/Layout/layout";
import { getAllSectionsRequest } from "../../../modules/students-modules/sections/Actions";

const SectionsList = () => {
  const sections = useSelector((state) => state.sections.sections);
  const isLoading = useSelector((state) => state.sections.isLoading);
  const userData = useSelector((state) => state.user.userData);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllSectionsRequest());
  }, []);
  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start">
          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            Videos
          </Heading>

          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={6}
            w="100%"
          >
            {sections?.map((section, idx) => {
              if (section?.subject_id == userData?.subject?.id)
                return (
                  <Link
                    to={`/videos/chapters/${section?.id}?name=${section?.name}`}
                    key={idx}
                  >
                    <VStack
                      bg="#ededed"
                      borderRadius="8px"
                      alignItems="start"
                      justifyContent="center"
                      p="10px"
                      spacing="20px"
                      h="70px"
                      w="100%"
                      cursor="pointer"
                    >
                      <HStack p="10px">
                        <TbBooks fontSize="35px" />

                        <VStack alignItems="flex-start">
                          <Text fontWeight="500">{section?.name}</Text>
                        </VStack>
                      </HStack>
                    </VStack>
                  </Link>
                );
            })}
          </Grid>
        </VStack>
      </Container>
    </Layout>
  );
};

export default SectionsList;
