import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { BiLowVision, BiShow } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubjectsRequest } from "../../modules/admin-modules/subjects/Actions";
import { loginUserAction } from "../../modules/auth/Actions";
import PublicLayout from "../public/Layout/layout";
const LoginForm = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [inputType, setInputType] = useState("password");
  const subjects = useSelector((state) => state.subjects.subjects);

  const subjectsOptions = subjects?.map((data) => {
    return { value: data.id, label: data.name };
  });

  useEffect(() => {
    dispatch(getAllSubjectsRequest());
  }, []);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
      width: "100%",
      padding: "20px",
      objectFit: "contain",
      borderRadius: "10px",
      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
      border: "solid 1px #010038",
      backgroundColor: "#fff",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  const validateUserName = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  const validatePassword = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  const validateSubject = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  return (
    <PublicLayout color="main_1" bg="white" register={false}>
      <Flex
        flexDirection="column"
        alignItems="center"
        my="50px"
        w="100%"
        h="100%"
      >
        <Formik
          initialValues={{
            phone: "",
            password: "",
            subject_id: null,
          }}
          onSubmit={(values) => {
            let formData = new FormData();
            formData.append("phone", values.phone);
            formData.append("password", values.password);
            formData.append("subject_id", values.subject_id);

            dispatch(loginUserAction(formData));
          }}
        >
          {({ setValues, errors, touched }) => (
            <Form
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flex
                flexDirection={"column"}
                sx={{ width: { base: "90%", md: "40%" } }}
              >
                <Heading textAlign={"center"} mb="10px">
                  Login
                </Heading>
                <Box w="100%">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="phone"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Phone
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      width: "100%",
                      padding: "10px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                    }}
                    name="phone"
                    validate={validateUserName}
                  />
                  {errors.phone && touched.phone && (
                    <Text color="red">{errors.phone}</Text>
                  )}
                </Box>
                <Box mt="35px" position={"relative"} w="100%">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="password"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Password
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      padding: "10px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                    name="password"
                    validate={validatePassword}
                    type={inputType}
                  />

                  <Icon
                    position={"absolute"}
                    right="10px"
                    top="50px"
                    fontSize="33px"
                    color="main_1"
                    as={inputType == "password" ? BiShow : BiLowVision}
                    onClick={() =>
                      inputType == "password"
                        ? setInputType("text")
                        : setInputType("password")
                    }
                  />
                  {errors.password && touched.password && (
                    <Text color="red" w="180px" textAlign="justify">
                      {errors.password}
                    </Text>
                  )}
                </Box>
                <Box w="100%" mt="35px">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="password"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Subject
                    </FormLabel>
                  </Flex>{" "}
                  <Field
                    as="select"
                    name="subject_id"
                    style={{
                      width: "100%",
                      padding: "13px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                    }}
                    validate={validateSubject}
                  >
                    <option>Select</option>
                    {subjectsOptions?.map((data, index) => {
                      return (
                        <option value={data?.value} key={index}>
                          {data?.label}
                        </option>
                      );
                    })}
                  </Field>
                  {errors.subject_id && touched.subject_id && (
                    <Text color="red" w="180px" textAlign="justify">
                      {errors.subject_id}
                    </Text>
                  )}
                </Box>
                <Button
                  mt="40px"
                  type="submit"
                  w="250px"
                  p="22px"
                  alignSelf={"center"}
                  isLoading={isLoading}
                  bg="main_1"
                  color="white"
                >
                  Login
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </PublicLayout>
  );
};

export default LoginForm;
