import Types from "./Types";

const INITIAL_STATE = {
  students: [],
  student: {},
  approvedStudents: [],
  pendeingStudents: [],
  studentReport: {},
  isLoading: false,
  isRestrict: false,
  isSuspend: false,
  count: "",
};

export default function students(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_STUDENTS_SUCCESS: {
      return {
        ...state,
        students: payload.data?.items,
        count: payload?.data?.pagination?.total_count,
      };
    }
    case Types.STUDENT_REQUESTS_LIST_SUCCESS: {
      return {
        ...state,
        pendeingStudents: payload.data?.items,
        count: payload?.data?.pagination?.total_count,
      };
    }
    case Types.STUDENT_APPROVED_LIST_SUCCESS: {
      return {
        ...state,
        approvedStudents: payload.data?.items,
        count: payload?.data?.pagination?.total_count,
      };
    }
    case Types.APPROVE_STUDENT_SUCCESS: {
      console.log(payload.payload);
      let id = payload.payload;

      const newStudents = state.pendeingStudents.filter(
        (item) => item.id !== id
      );
      return {
        ...state,
        pendeingStudents: newStudents,
      };
    }
    case Types.REJECT_STUDENT_SUCCESS: {
      let id = payload.payload;

      const newStudents = state.pendeingStudents.filter(
        (item) => item.id !== id
      );
      return {
        ...state,
        pendeingStudents: newStudents,
      };
    }
    case Types.GET_STUDENT_REPORT_SUCCESS: {
      return {
        ...state,
        studentReport: payload.data.data,
      };
    }

    case Types.GET_STUDENT_SUCCESS: {
      return {
        ...state,
        student: payload?.data,
      };
    }

    case Types.EDIT_STUDENT_SUCCESS: {
      let data = payload.data;
      const students = state.students;
      let idx = 0;
      students.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      students[idx] = data;
      return {
        ...state,
        students: students,
      };
    }

    // create
    case Types.CREATE_STUDENT_SUCCESS: {
      const data = payload.data.data;
      let newArray = state.students.slice();
      let index = state.students.length + 1;
      newArray.splice(index, 0, data);
      return {
        ...state,
        students: newArray,
      };
    }
    case Types.CLEAR_SESSION_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_STUDENTS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.SUSPEND_LOADING: {
      return {
        ...state,
        isSuspend: payload,
      };
    }

    case Types.RESTRICT_LOADING: {
      return {
        ...state,
        isRestrict: payload,
      };
    }

    case Types.DELETE_STUDENT_SUCCESS: {
      let id = payload.payload;
      let data = payload.result.data.data;
      const students = state.students;
      let idx = 0;
      students.forEach((table, index) => {
        if (table.id === id) {
          idx = index;
          return;
        }
      });

      students[idx].deleted_at = data?.deleted_at;
      return {
        ...state,
        students: students,
      };
    }
    case Types.RESTRICT_STUDENT_SUCCESS: {
      let id = payload.payload.id;
      let data = payload.result.data.data;

      const students = state.students;
      let idx = 0;
      students.forEach((table, index) => {
        if (table.id === id) {
          idx = index;
          return;
        }
      });

      students[idx].restrict = data?.restrict;
      return {
        ...state,
        students: students,
      };
    }
    case Types.ADD_BULK_STUDENT_SUCCESS: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
}
