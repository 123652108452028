import {
  Container,
  Heading,
  HStack,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Square,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiAttachment2 } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Layout from "../../../components/student/Layout/layout";
import { getLessonsByChapterIdRequest } from "../../../modules/students-modules/lessons/Actions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTransition } from "react";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
const StudentModelAnswer = () => {
  const baseUrl = "https://asquera.com/asquera";
  const [selectedData, setSelectedData] = useState(null);
  const [searchParams] = useSearchParams();
  const [isPending, startTransition] = useTransition();
  const { id } = useParams();
  const dispatch = useDispatch();
  const chapterLessons = useSelector(
    (state) => state.chapterLessons.chapterLessons
  );
  const isLoading = useSelector((state) => state.chapterLessons.isLoading);

  useEffect(() => {
    if (id) dispatch(getLessonsByChapterIdRequest(id));
  }, [id]);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <HiArrowRight
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#f37335",
          fontSize: "30px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <HiArrowLeft
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#f37335",
          fontSize: "30px",
        }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const printPDF = () => {
    // Create a reference to the PDF link
    const pdfLink = document.getElementById("pdfLink");

    // Open the PDF link in a new window or tab
    const pdfWindow = window.open(pdfLink.href);

    // Wait for the PDF to load, then trigger the print dialog
    pdfWindow.onload = function () {
      pdfWindow.print();
    };
  };
  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start" mt={20}>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/model-answers/sections">
                <Text>PDFS</Text>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <Text>{searchParams.get("name")}</Text>
            </BreadcrumbItem>
          </Breadcrumb>

          <Heading as="h3" color="main_1" textAlign="center" w="100%" mt={4}>
            {searchParams.get("name")}
          </Heading>

          <HStack
            flexDirection={{ base: "column", md: "column" }}
            w="100%"
            justifyContent="center"
            alignItems="center"
            gap={{ base: 10, md: 4 }}
          >
            {/* left side */}
            <VStack w={{ base: "100%", md: "80%" }}>
              <Slider {...settings} className="slider">
                {chapterLessons?.map((lesson, idx) => {
                  return (
                    <HStack
                      display="flex!important"
                      flexDir="row"
                      cursor={"pointer"}
                      boxShadow={
                        lesson?.id == selectedData?.id
                          ? "2px 3px 10px #80808045"
                          : "none"
                      }
                      bg="main_1_light"
                      color="main_1"
                      borderRadius="8px"
                      p="10px"
                      w="100%"
                      key={idx}
                      onClick={() => {
                        startTransition(() => {
                          setSelectedData(lesson);
                        });
                      }}
                    >
                      <Icon as={RiAttachment2} fontSize="18px" />
                      <Text>{lesson?.title}</Text>
                    </HStack>
                  );
                })}
              </Slider>
            </VStack>

            {/* right side */}

            <VStack w={{ base: "100%", md: "90%" }} h="100%" spacing="40px">
              <Box
                as="iframe"
                title="subject"
                width="100%"
                height={"600"}
                src={`${baseUrl}/${selectedData?.file}`}
                frameBorder="4"
              ></Box>
            </VStack>
          </HStack>
        </VStack>
      </Container>
    </Layout>
  );
};

export default StudentModelAnswer;
