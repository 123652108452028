export default {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",

  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",

  USER_VOUCHER: "USER_VOUCHER",
  USER_VOUCHER_SUCCESS: "USER_VOUCHER_SUCCESS",
  SET_AUTH_IS_LOADING: "SET_AUTH_IS_LOADING",

  YEARS: "YEARS",
  YEARS_SUCCESS: "YEARS_SUCCESS",
  FACULTIES: "FACULTIES",
  FACULTIES_SUCCESS: "FACULTIES_SUCCESS",
  SIGNUP_USER: "SIGNUP_USER",
  SIGNUP_USER_SUCCESS: "SIGNUP_USER_SUCCESS",
};
