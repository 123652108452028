import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { RiAddFill, RiFileExcel2Line, RiSearch2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { openCreateExamDataModel } from "../../../modules/admin-modules/models/Actions";

import { addBulkCorrectionRequest } from "../../../modules/admin-modules/exams/Actions";

const SearchBar = ({ setAdd }) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.exams.count);

  return (
    <VStack w="100%" my={{ base: "50px", md: "30px" }}>
      <Flex
        alignItems="center"
        w="100%"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Text
          fontSize="24px"
          fontWeight="600"
          color="main_1"
          width={{ base: "100%", md: "20%" }}
          mb={{ base: "10px", md: 0 }}
        >
          الاختبارات
        </Text>

        <HStack spacing="7px" gap={2}>
          <Button
            borderColor="main_1"
            border="1px solid"
            mx="10px"
            borderRadius="16px"
            leftIcon={<RiFileExcel2Line />}
            color="main_1"
            bg="white"
            _focus={{ outline: "none" }}
            position="relative"
          >
            <Text>Add Manual Correction in Bulk</Text>
            <Input
              onChange={(e) => {
                let formData = new FormData();
                formData.append("marks_bulk", e.target.files[0]);
                dispatch(addBulkCorrectionRequest(formData));
              }}
              type="file"
              placeholder="اختر ملف ليتم رفعه"
              opacity="0"
              position="absolute"
              w="100%"
              h="100%"
            />
          </Button>
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openCreateExamDataModel());
            }}
          >
            إضافة اختبار
          </Button>

          <Box display={{ base: "none", md: "block" }}>
            <LogoutMenu />
          </Box>
        </HStack>
      </Flex>

      <Text w="100%" fontSize="20px" textAlign="right">
        عدد الاختبارات :{count}{" "}
      </Text>
    </VStack>
  );
};

export default SearchBar;
