import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { RiCheckFill, RiFileEditLine, RiStarSFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CTable from "../../../components/Table/table";
import axiosInstance from "../../../helpers/api";
import {
  openCreateQuestionModel,
  openCreateStudent,
} from "../../../modules/admin-modules/models/Actions";
import {
  bookmarkQuestionBankRequest,
  deleteQuestionBankRequest,
} from "../../../modules/admin-modules/questions-bank/Actions";

const QuestionsBankTable = ({ setAdd, setSelectedData }) => {
  const dispatch = useDispatch();
  const questionsBank = useSelector(
    (state) => state.questionsBank.questionsBank
  );
  const isLoading = useSelector((state) => state.questionsBank.isLoading);
  const bookmarkLoading = useSelector(
    (state) => state.questionsBank.bookmarkLoading
  );
  const suspendLoading = useSelector(
    (state) => state.questionsBank.suspendLoading
  );

  const count = useSelector((state) => state.questionsBank.count);

  const [checkedItems, setCheckedItems] = React.useState([false, false]);
  const [checkedIds, setCheckedIds] = useState([]);

  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });

  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const defcultyLevels = {
    1: "notes",
    2: "كتاب المدرسة",
    3: "hard questions",
    4: "امتحانات السنين السابقة",
    5: "Revision",
  };
  const Data = questionsBank?.map((data, index) => {
    return {
      ...data,
      select: (
        <Checkbox
          isChecked={checkedItems[index]}
          onChange={(e) => {
            let newData = checkedItems;
            newData[index] = e.target.checked;
            setCheckedItems(newData);
            if (e.target.checked) {
              setCheckedIds((s) => [...s, data?.id]);
            } else {
              let selectedData = questionsBank;
              const filter = checkedIds.filter(
                (data) => data != selectedData[index].id
              );
              setCheckedIds(filter);
            }
          }}
        ></Checkbox>
      ),
      defcultyLevels: <Text>{defcultyLevels?.[data?.difficulty_level]}</Text>,
      index: `#${index + 1}`,
      chapter_id: data?.chapter?.id,
      questionTable: (
        <div dangerouslySetInnerHTML={{ __html: data?.question }} />
      ),
      edit: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiFileEditLine />}
          onClick={() => {
            setAdd(false);
            dispatch(openCreateQuestionModel());
          }}
        />
      ),
      delete: (
        <Button
          onClick={() => dispatch(deleteQuestionBankRequest(data?.id))}
          isLoading={data?.id == selected.id ? suspendLoading : false}
          leftIcon={data?.deleted_at == null ? null : <RiCheckFill />}
          bg={data?.deleted_at == null ? "main_1" : "red.300"}
          color={data?.deleted_at == null ? "white" : "black"}
        >
          {data?.deleted_at == null ? " حظر السؤال" : "تم الحظر"}
        </Button>
      ),
      bookmark: (
        <IconButton
          aria-label="bookmark"
          as={RiStarSFill}
          bg="transparent"
          _focus={{ outline: "none" }}
          onClick={() => dispatch(bookmarkQuestionBankRequest(data?.id))}
          isLoading={data?.id == selected.id ? bookmarkLoading : false}
          color={data?.book_marked == 0 ? "gray.300" : "yellow.300"}
        />
      ),
    };
  });
  const checkAllQuestions = (val) => {
    const allData = questionsBank?.map(() => val);
    if (val) {
      const allData = questionsBank?.map((data) => data?.id);
      setCheckedIds(allData);
    } else {
      setCheckedIds([]);
    }
    setCheckedItems(allData);
  };

  const suspendAll = () => {
    const formData = new FormData();
    formData.append("_method", "PUT");
    checkedIds.map((val, idx) => formData.append(`question_ids[${idx}]`, val));
    try {
      axiosInstance.post("/admin/bank_questions/suspend", formData);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("حدث خطا, برجاء المحاولى مرة اخري");
    }
  };
  const columns = [
    {
      Header: (
        <Checkbox
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={(e) => checkAllQuestions(e.target.checked)}
        ></Checkbox>
      ),
      accessor: "select",
    },
    {
      Header: "رقم ",
      accessor: "index",
    },
    {
      Header: " نص السؤال",
      accessor: "questionTable",
    },
    {
      Header: "مستوي السؤال ",
      accessor: "defcultyLevels",
    },
    {
      Header: "تعديل",
      accessor: "edit",
    },

    {
      Header: "bookmark",
      accessor: "bookmark",
    },

    {
      Header: "حذف",

      accessor: "delete",
    },
  ];

  const actions = (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<RiFileEditLine />}
        size="sm"
        fontSize="20px"
        variant="outline"
        border="none"
      />
      <MenuList>
        <MenuItem
          onClick={() => {
            setAdd(false);
            dispatch(openCreateStudent());
          }}
        >
          تعديل
        </MenuItem>
      </MenuList>
    </Menu>
  );
  return (
    <Box h="100vh">
      <Button
        onClick={suspendAll}
        my="10px"
        display={checkedItems.includes(true) ? "block" : "none"}
        float="left"
      >
        suspend selected questions
      </Button>
      <CTable
        selectedData={viewData}
        Columns={columns}
        Data={Data}
        path={"openModel"}
        Actions={actions}
        Title={``}
        subTitle=""
        btnTitle=""
        placeHolder=""
        noSearchBar={true}
        noFilter={true}
        footerBtnTitle
        filterList
        addButton
        addButtonTitle=""
        setPage={setPage}
        setPerPage={setPerPage}
        currentpage={pageNumber}
        setPageNumber={setPageNumber}
        noSecondSearchBar={true}
        isLoading={isLoading}
        perPage={size}
        totalPage={Math.ceil(count / 1000)}
      />
    </Box>
  );
};

export default QuestionsBankTable;
