import axiosInstance from "../../../helpers/api";

export const getAllGroups = (body) => {
  return axiosInstance.get("/admin/groups", body);
};

export const getPerformanceGroups = (body) => {
  return axiosInstance.get("/admin/performance_groups", body);
};

export const editGroups = (formData) => {
  return axiosInstance.post(`/admin/groups/${formData.id}`, formData.formData);
};

export const createGroups = (formData) => {
  return axiosInstance.post("/admin/groups", formData);
};

export const deleteGroup = (id) => {
  return axiosInstance.delete(`/admin/groups/${id}`);
};
