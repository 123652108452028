import Types from "./Types";

const INITIAL_STATE = {
  exams: [],
  chapterExams: [],
  chapterExam: [],
  chapterExamDetails: [],
  chapterExamAnswers: [],
  examQuestions: [],
  examCorrection: {},
  isAnswersLoading: false,
  exam: {},
  isLoading: false,
  count: "",
};

export default function chapterExams(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_ALL_EXAMS_SUCCESS: {
      return {
        ...state,
        exams: payload.data,
      };
    }
    case Types.GET_CHAPTER_EXAM_SUCCESS: {
      return {
        ...state,
        chapterExam: payload.data,
      };
    }
    case Types.GET_CHAPTER_EXAM_DETAILS_SUCCESS: {
      return {
        ...state,
        chapterExamDetails: payload.data,
      };
    }

    case Types.GET_EXAMS_BY_CHAPTER_ID_SUCCESS: {
      return {
        ...state,
        chapterExams: payload.data,
        count: payload.data.length,
      };
    }

    case Types.GET_EXAMS_BY_ID_SUCCESS: {
      return {
        ...state,
        exam: payload.data,
      };
    }

    case Types.GET_EXAM_QUESTIONS_SUCCESS: {
      return {
        ...state,
        examQuestions: payload.data,
      };
    }
    case Types.GET_EXAM_CORRECTION_SUCCESS: {
      return {
        ...state,
        examCorrection: payload.data,
      };
    }

    case Types.ADD_EXAMS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.SEND_ANSWERS_LOADING: {
      return {
        ...state,
        isAnswersLoading: payload,
      };
    }
    case Types.SEND_ANSWERS_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.EXAM_REDO_SUCCESS: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
