import { Box, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Layout from "../../../components/student/Layout/layout";
import VideoPreview from "../../../containers/student/video/videoPreview";
import {
  getVideoByIdRequest,
  getVideoOtpRequest,
  getVideosByChapterIdRequest,
} from "../../../modules/students-modules/videos/Actions";

const QrCodeVideos = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { video_id } = useParams();
  const videoDetails = useSelector((state) => state.chapterVideos.videoDetails);

  useEffect(() => {
    if (video_id) getVideoData(video_id);
  }, [video_id]);

  const getVideoData = async (id) => {
    setLoading(true);
    dispatch(getVideoOtpRequest(id));
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  useEffect(() => {
    if (video_id) dispatch(getVideoByIdRequest(video_id));
  }, [video_id]);

  return (
    <Layout color="main_1" bg="white" isLoading={loading}>
      <Stack p={8} alignItems="center" justifyContent="center" mt="30px">
        <Box h="600px" w={{ base: "100%", md: "700px" }}>
          {!loading && <VideoPreview data={videoDetails} loading={loading} />}
        </Box>
      </Stack>
    </Layout>
  );
};

export default QrCodeVideos;
