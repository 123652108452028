import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PublicLayout from "../../components/public/Layout/layout";
import AboutTeacher from "../../containers/teacher/about-teacher";
import StudentsComp from "../../containers/teacher/studentsComp";
import TeacherData from "../../containers/teacher/teacher-data";

const TeacherTemplate = () => {
  const [data, setData] = useState(teacherData);
  const [teacherData, setTeacherData] = useState({});
  const { name } = useParams();

  useEffect(() => {
    const filterData = data.find((val) => val?.key == name);
    setTeacherData(filterData);
  }, [name]);
  return (
    <PublicLayout color="white" bg="main_1" type="teacher">
      <motion.div initial={{ x: "100%" }} animate={{ x: "calc(100vw - 100%)" }}>
        <TeacherData data={teacherData} />
      </motion.div>
      {teacherData?.description && (
        <motion.div
          initial={{ scale: 1.3 }}
          whileInView={{ scale: 1 }}
          viewport={{ once: true, amount: "some" }}
        >
          <AboutTeacher data={teacherData} />
        </motion.div>
      )}
      {teacherData?.topStudents && (
        <motion.div
          initial={{ scale: 1.3 }}
          whileInView={{ scale: 1 }}
          viewport={{ once: true, amount: "some" }}
        >
          <StudentsComp data={teacherData} />
        </motion.div>
      )}
    </PublicLayout>
  );
};

export default TeacherTemplate;
