import React, { useState } from "react";
import { Container, Grid, Flex, Text, Image, Stack } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
const AboutTeacher = ({ t, data }) => {
  return (
    <Container maxW={"container.xl"} mt="200px" mb="80px">
      <Flex
        alignItems={"center"}
        justifyContent="center"
        flexDirection={"column"}
        id="resources"
        gap={6}
      >
        <Text fontSize="32px" fontWeight="600" textTransform={"uppercase"}>
          About Teacher
        </Text>
        <Text
          fontSize="28px"
          fontWeight="400"
          textTransform={"uppercase"}
          textAlign="center"
        >
          {data?.description}
        </Text>
      </Flex>
    </Container>
  );
};

export default withTranslation()(AboutTeacher);
