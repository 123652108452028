import {
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { RiAddFill, RiSearch2Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import Select from "react-select";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import {
  openCreateEvent,
  openCreateStreaming,
} from "../../../modules/admin-modules/models/Actions";

const SearchBar = ({ setAdd, setSelectedData }) => {
  const dispatch = useDispatch();
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "white",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  return (
    <VStack w="100%" my={{ base: "50px", md: "30px" }}>
      <Flex
        alignItems="center"
        w="100%"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Text
          fontSize="24px"
          fontWeight="600"
          color="main_1"
          width={{ base: "100%", md: "20%" }}
          mb={{ base: "10px", md: 0 }}
        >
          الأحداث
        </Text>

        <Flex alignItems="center" gap={2}>
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            mx="8px"
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openCreateEvent());
            }}
          >
            إضافة حدث
          </Button>

          <Box display={{ base: "none", md: "block" }}>
            <LogoutMenu />
          </Box>
        </Flex>
      </Flex>
      <Text w="100%" fontSize="20px" textAlign="right">
        {" "}
        عدد الأحداث:
      </Text>

      {/* <HStack
        w="100%"
        justifyContent="space-between"
        bg="#f5f5f5"
        mt="40px !important"
        p="10px"
        mb="-30px !important"
      >
        <HStack spacing="5px">
          <Select
            options={options}
            onChange={(data) => {}}
            styles={colourStyles}
            placeholder="الصف"
          />

          <InputGroup w="400px">
            <InputLeftElement
              pointerEvents="none"
              children={<RiSearch2Fill color="main_1" fontSize="20px" />}
            />
            <Input
              type="text"
              placeholder="عنوان الحدث"
              borderRadius="16px"
              bg="white"
            />
          </InputGroup>
        </HStack>

        <HStack spacing="5px">
          <Select
            options={options}
            onChange={(data) => {}}
            styles={colourStyles}
            placeholder="التصنيف"
          />

          <Select
            options={options}
            onChange={(data) => {}}
            styles={colourStyles}
            placeholder="الأحدث"
          />
        </HStack>
      </HStack> */}
    </VStack>
  );
};

export default SearchBar;
