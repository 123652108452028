import axiosInstance from "../../helpers/api";

export const Login = (formData) => {
  return axiosInstance.post(`/login`, formData);
};

export const signup = (formData) => {
  return axiosInstance.post(`/circles/users/register`, formData);
};

export const faculties = () => {
  return axiosInstance.get(`/circles/faculties`);
};

export const years = () => {
  return axiosInstance.get(`/circles/years`);
};

export const Logout = () => {
  return axiosInstance.get(`/logout`);
};
export const userVoucher = (formData) => {
  //console.log(formData);
  return axiosInstance.post(`/students/users_payments`, formData?.formData);
};
