import { Flex, Box, Container } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import DashboardWrapper from "../../Auth/AuthWrapper";
import SuperAdminSidenavbar from "../sideNavbars/superAdminSidenavbar";
import TeacherSidenavbar from "../sideNavbars/teacherSidenavbar";
import MobileSidebar from "../sideNavbars/mobile-sidebar";
const Layout = ({ children }) => {
  const userData = useSelector((state) => state.user.userData);

  return (
    <DashboardWrapper roles={["Admin", "Super Admin", "Teacher"]}>
      <Flex w="100%" h="100%" dir="rtl">
        <Box w={{ base: "20%" }} display={{ base: "none", md: "block" }}>
          {userData?.role?.length > 0 ? (
            userData?.role[0]?.name == "Teacher" ? (
              <TeacherSidenavbar />
            ) : (
              <SuperAdminSidenavbar />
            )
          ) : null}
        </Box>
        <MobileSidebar />
        <Container h="100%" maxW="container.xl" w={{ base: "100%", lg: "80%" }}>
          <Box w="100%" h="100%" overflowX="scroll">
            {children}
          </Box>
        </Container>
      </Flex>
    </DashboardWrapper>
  );
};

export default Layout;
