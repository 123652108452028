import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  chakra,
  Container,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/student/Layout/layout";
import { getAllStudentChaptersRequest } from "../../../modules/students-modules/chapters/Actions";
import { getMissedQuestionsRequest } from "../../../modules/students-modules/questions/Actions";

const Questions = () => {
  const baseUrl = "https://asquera.com/asquera";

  const dispatch = useDispatch();
  const bookmark = useSelector((state) => state.questions.bookmark);
  const missed = useSelector((state) => state.questions.missed);
  const chapters = useSelector(
    (state) => state.studentChapters.studentChapters
  );

  const [selectedTab, setSelectedTab] = useState(1);
  useEffect(() => {
    // dispatch(getBookmarkedQuestionsRequest());
    dispatch(getAllStudentChaptersRequest());
  }, []);

  useEffect(() => {
    dispatch(getMissedQuestionsRequest({ type: selectedTab }));
  }, [selectedTab]);
  const tabs = [
    {
      title: "Popular Questions",
      data: bookmark,
    },
    {
      title: "Most Missed Questions",
      data: missed,
    },
  ];
  return (
    <Layout color="main_1" bg="white" isLoading={false}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%">
          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            Most Missed Questions
          </Heading>
          <Flex gap={2} my={4} alignItems="center" justifyContent="center">
            <Button
              onClick={() => setSelectedTab(1)}
              border={selectedTab == 1 ? "1px solid blue" : "transparent"}
            >
              Normal Exams
            </Button>
            <Button
              onClick={() => setSelectedTab(7)}
              border={selectedTab == 7 ? "1px solid blue" : "transparent"}
            >
              Study with enough Exams
            </Button>
          </Flex>
          <Flex
            flexDirection="row"
            flexWrap="wrap"
            gap={4}
            alignItems="center"
            justifyContent="center"
          >
            {missed?.length != 0 &&
              chapters?.map((chapter, index) => {
                return (
                  <Accordion
                    defaultIndex={[0]}
                    allowMultiple
                    mt="5px"
                    w={{ base: "100%", md: "100%" }}
                  >
                    <AccordionItem border="0">
                      <h2>
                        <AccordionButton
                          bg="main_1"
                          color="white"
                          borderRadius="8px"
                          _focus={{ outline: "none" }}
                          _hover={{ bg: "main_1" }}
                        >
                          <Box flex="1" textAlign="left">
                            {chapter?.name}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} border="0">
                        <Grid flexWrap="wrap" gap="2">
                          {missed?.map((question, index) => {
                            if (question?.question?.chapter_id == chapter?.id)
                              return (
                                <VStack
                                  bg="main_1_light"
                                  borderRadius="8px"
                                  p="5px"
                                  alignItems="flex-start"
                                  key={index}
                                >
                                  <HStack alignItems="flex-start">
                                    {question?.question?.image && (
                                      <Image
                                        src={
                                          baseUrl +
                                          "/" +
                                          question?.question?.image
                                        }
                                        h="100%"
                                        w="30%"
                                        border="1px solid"
                                        borderRadius="8px"
                                      />
                                    )}
                                    <VStack alignItems="flex-start">
                                      {question?.question?.comprehension && (
                                        <Text color="main_1">
                                          <chakra.span color="#514e4ebd">
                                            Comprehension :
                                          </chakra.span>{" "}
                                          {question?.question?.comprehension}
                                        </Text>
                                      )}
                                      <Text color="main_1">
                                        <chakra.span color="#514e4ebd">
                                          Question :{" "}
                                        </chakra.span>{" "}
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              question?.question?.question,
                                          }}
                                        />
                                      </Text>
                                      <Text color="main_1">
                                        {" "}
                                        <chakra.span color="#514e4ebd">
                                          Right Answer :
                                        </chakra.span>{" "}
                                        {
                                          question?.question?.answers?.find(
                                            (val) => val?.right_answer == 1
                                          )?.answer
                                        }
                                      </Text>
                                      <Text color="main_1">
                                        {" "}
                                        <chakra.span color="#514e4ebd">
                                          Student Answer :
                                        </chakra.span>{" "}
                                        {
                                          question?.answers?.find(
                                            (val) =>
                                              val?.id ==
                                              question?.student_answer_id
                                          )?.answer
                                        }
                                      </Text>
                                    </VStack>
                                  </HStack>
                                </VStack>
                              );
                          })}
                        </Grid>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                );
              })}
          </Flex>

          {/* tabs */}
          {/* <Tabs
              w="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <TabList bg="main_1_light" p="3px" borderRadius="8px">
                {tabs?.map((data, index) => {
                  return (
                    <Tab
                      key={index}
                      _focus={{ outline: "none" }}
                      borderRadius="8px"
                      _selected={{ bg: "main_1", color: "white" }}
                    >
                      {data?.title}
                    </Tab>
                  );
                })}
              </TabList>
              <TabPanels>
                <Flex gap={2} my={4} alignItems="center" justifyContent="center">
                  <Button onClick={() => setSelectedTab(1)}>Normal Exams</Button>
                  <Button onClick={() => setSelectedTab(7)}>
                    Study with enough Exams
                  </Button>
                </Flex>
                {tabs?.map((data, idx) => {
                  return (
                    <TabPanel>
                      {chapters?.map((chapter, index) => {
                        return (
                          <Accordion defaultIndex={[0]} allowMultiple mt="5px">
                            <AccordionItem border="0">
                              <h2>
                                <AccordionButton
                                  bg="main_1"
                                  color="white"
                                  borderRadius="8px"
                                  _focus={{ outline: "none" }}
                                  _hover={{ bg: "main_1" }}
                                >
                                  <Box flex="1" textAlign="left">
                                    {chapter?.name}
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4} border="0">
                                <Grid
                                  flexWrap="wrap"
                                  templateColumns={
                                    idx == 0
                                      ? {
                                          base: "repeat(1,1fr)",
                                          lg: "repeat(2, 1fr)",
                                        }
                                      : "repeat(1, 1fr)"
                                  }
                                  gap="2"
                                >
                                  {data.data?.map((question, index) => {
                                    if (
                                      question?.question?.chapter_id ==
                                      chapter?.id
                                    )
                                      return (
                                        <VStack
                                          bg="main_1_light"
                                          borderRadius="8px"
                                          p="5px"
                                          alignItems="flex-start"
                                        >
                                          <HStack alignItems="flex-start">
                                            {question?.question?.image && (
                                              <Image
                                                src={question?.question?.image}
                                                w={idx == 0 ? "90px" : "150px"}
                                                h="100%"
                                                border="1px solid"
                                                borderRadius="8px"
                                              />
                                            )}
                                            <VStack alignItems="flex-start">
                                              {question?.question
                                                ?.comprehension && (
                                                <Text color="main_1">
                                                  <chakra.span color="#514e4ebd">
                                                    Comprehension :
                                                  </chakra.span>{" "}
                                                  {
                                                    question?.question
                                                      ?.comprehension
                                                  }
                                                </Text>
                                              )}
                                              <Text color="main_1">
                                                <chakra.span color="#514e4ebd">
                                                  Question :{" "}
                                                </chakra.span>{" "}
                                                {question?.question?.question}
                                              </Text>
                                              <Text color="main_1">
                                                {" "}
                                                <chakra.span color="#514e4ebd">
                                                  Right Answer :
                                                </chakra.span>{" "}
                                                {
                                                  question?.question?.answers?.filter(
                                                    (val) =>
                                                      val?.right_answer == 1
                                                  )[0]?.answer
                                                }
                                              </Text>
                                            </VStack>
                                          </HStack>
                                        </VStack>
                                      );
                                  })}
                                </Grid>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        );
                      })}
                    </TabPanel>
                  );
                })}
              </TabPanels>
            </Tabs> */}
        </VStack>
      </Container>
    </Layout>
  );
};

export default Questions;
