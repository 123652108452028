export default {
    //get all CHAPTERs
    GET_CHAPTERS_REQUEST: 'GET_CHAPTERS_REQUEST',
    GET_CHAPTERS_SUCCESS: 'GET_CHAPTERS_SUCCESS',
    GET_CHAPTERS_FAIL: 'GET_CHAPTERS_FAIL',

    //edit CHAPTERs
    EDIT_CHAPTER_REQUEST: 'EDIT_CHAPTER_REQUEST',
    EDIT_CHAPTER_SUCCESS: 'EDIT_CHAPTER_SUCCESS',
    EDIT_CHAPTER_FAIL: 'EDIT_CHAPTER_FAIL',

    RESTRICT_CHAPTER_REQUEST: 'RESTRICT_CHAPTER_REQUEST',
    RESTRICT_CHAPTER_SUCCESS: 'RESTRICT_CHAPTER_SUCCESS',
    RESTRICT_CHAPTER_FAIL: 'RESTRICT_CHAPTER_FAIL',

    ACTIVE_CHAPTER_REQUEST:"ACTIVE_CHAPTER_REQUEST",
    ACTIVE_CHAPTER_SUCCESS:"ACTIVE_CHAPTER_SUCCESS",
    ACTIVE_CHAPTER_FAIL:"ACTIVE_CHAPTER_FAIL",

    //create CHAPTER
    CREATE_CHAPTER_REQUEST: 'CREATE_CHAPTER_REQUEST',
    CREATE_CHAPTER_SUCCESS: 'CREATE_CHAPTER_SUCCESS',
    CREATE_CHAPTER_FAIL: 'CREATE_CHAPTER_FAIL',

    DELETE_CHAPTER_REQUEST: 'DELETE_CHAPTER_REQUEST',
    DELETE_CHAPTER_SUCCESS: 'DELETE_CHAPTER_SUCCESS',
    DELETE_CHAPTER_FAIL: 'DELETE_CHAPTER_FAIL',

    GET_CHAPTER_REQUEST: 'GET_CHAPTER_REQUEST',
    GET_CHAPTER_SUCCESS: 'GET_CHAPTER_SUCCESS',
    GET_CHAPTER_FAIL: 'GET_CHAPTER_FAIL',

    //loading
    ADD_CHAPTERS_LOADING: 'ADD_CHAPTERS_LOADING'
};
