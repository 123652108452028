import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import QRCode from "qrcode.react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MainModel from "../../../../components/Models/main-model";
import { closeUserQRCodeModel } from "../../../../modules/admin-modules/models/Actions";

const UserQRCode = ({ data }) => {
  const dispatch = useDispatch();
  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        QR Code
      </Heading>
    </Box>
  );

  const openUserData = useSelector((state) => state.model.isOpen14);

  const closeFunc = () => {
    dispatch(closeUserQRCodeModel());
  };
  return (
    <MainModel
      header={header}
      closeFunc={closeFunc}
      openVar={openUserData}
      buttonTitle={null}
      buttonFunc={null}
    >
      <VStack>
        <Text my={4} textAlign="center" width="200px" fontWeight="bold">
          {data?.subject?.name}
        </Text>
        <Text my={4} textAlign="center" width="200px" fontWeight="bold">
          {data?.name}
        </Text>
        <Text my={4} textAlign="center" width="200px" fontWeight="bold">
          {data?.user_name}
        </Text>
        <QRCode
          size={200}
          value={`https://asquera.com/scan-code/${data?.id}/attendence`}
        />
      </VStack>
    </MainModel>
  );
};

export default UserQRCode;
