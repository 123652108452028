import {
  Button,
  CircularProgress,
  Container,
  Heading,
  HStack,
  Icon,
  Stack,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { Beforeunload, useBeforeunload } from "react-beforeunload";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ExamQuestions from "../../../containers/student/exams/exam-questions";
import Layout from "../../../components/student/Layout/layout";
import ExamAnswersLoading from "../../../containers/student/models/exam-answers-loading";
import Timer from "../../../containers/student/exams/timer";
import {
  closeExamAnswersLoading,
  openExamAnswersLoading,
  openExamHintkModel,
} from "../../../modules/admin-modules/models/Actions";
import {
  getChapterExamByIdRequest,
  sendChapterExamAnswersRequest,
} from "../../../modules/students-modules/exams/Actions";
import { useParams, useSearchParams } from "react-router-dom";
import ExamSolvedQuestions from "../../../containers/student/exams/exam-solved-questions";
import { toast } from "react-toastify";
import ExamHint from "../../../containers/student/exams/exam-hint";
import IndexList from "../../../containers/student/studyWithEnough/IndexList";

const StudyWithEnoughSolveExam = () => {
  const [examQuestions, setExamQuestions] = useState([]);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const parsed = searchParams.get("name");

  const { id: examId } = useParams();

  const examQuestionsData = useSelector(
    (state) => state.chapterExams.chapterExamDetails
  );
  const studentExamQuestions = useSelector(
    (state) => state.studentExams.chapterExamDetails
  );
  const isLoading = useSelector((state) => state.chapterExams.isLoading);
  const exam = useSelector((state) => state.chapterExams.chapterExamDetails);

  const [answers, setAnswers] = useState([]);
  const [selectedQuestionIdx, setSelectedQuestionIdx] = useState(0);
  const videoData = exam?.[selectedQuestionIdx]?.url?.split("/");
  const splitLength = exam?.[selectedQuestionIdx]?.url?.split("/")?.length;
  const videoId = videoData?.[splitLength - 1];

  useBeforeunload((event) => {
    if (Object.keys(answers)?.length != examQuestions?.length) {
      event.preventDefault();
      return "you will lose your data";
    }
  });
  useEffect(() => {
    dispatch(getChapterExamByIdRequest(examId));
  }, [examId, window.location.pathname]);

  useEffect(() => {
    setExamQuestions(examQuestionsData);
  }, [studentExamQuestions, examQuestionsData]);

  const handelNextQuestion = () => {
    if (selectedQuestionIdx < 0)
      setSelectedQuestionIdx(examQuestions?.length - 1);
    else if (selectedQuestionIdx >= examQuestions?.length - 1)
      setSelectedQuestionIdx(0);
    else setSelectedQuestionIdx((s) => s + 1);
  };
  const handelPrevQuestion = () => {
    if (selectedQuestionIdx > examQuestions?.length - 1)
      setSelectedQuestionIdx(0);
    if (selectedQuestionIdx <= 0)
      setSelectedQuestionIdx(examQuestions?.length - 1);
    else setSelectedQuestionIdx((s) => s - 1);
  };

  const handelSendAnswers = async () => {
    const action = () => {
      dispatch(closeExamAnswersLoading());
      setAnswers([]);
      dispatch(getChapterExamByIdRequest(examId));
    };
    const answersLength = answers?.length;
    let formData = {
      exam_id: examId,
      question_id: answers?.[answersLength - 1]?.question_id,
      answer_id: answers?.[answersLength - 1]?.answer_id,
    };
    if (answers?.[answersLength - 1]?.answer_id) {
      dispatch(openExamAnswersLoading());
      dispatch(sendChapterExamAnswersRequest(formData, action));
    } else toast.error("Choose answer first");
  };

  return (
    <Layout color="main_1" bg="white" isLoading={false}>
      <Beforeunload onBeforeunload={() => "You’ll lose your data!"} />

      <Container
        maxW="container.xl"
        h="100%"
        mb="165px"
        w="100%"
        onSelect={(e) => e.preventDefault()}
        onMouseDown={(e) => e.preventDefault()}
      >
        <Stack
          alignItems="start"
          my="250px"
          gap="30px"
          flexDirection={{ base: "column", md: "row" }}
        >
          <IndexList
            examQuestions={examQuestions}
            setSelectedQuestionIdx={setSelectedQuestionIdx}
            selectedQuestionIdx={selectedQuestionIdx}
          />
          <VStack spacing={"70px"} h="100%" w={{ base: "100%", md: "70%" }}>
            <Heading
              as="h3"
              color="main_1"
              textAlign="center"
              w="100%"
              textTransform="capitalize"
            >
              {parsed}
            </Heading>
            {exam?.timer == 1 && (
              <Timer time={exam?.time} handelSendAnswers={handelSendAnswers} />
            )}
            {/* questions polts */}

            {examQuestions[selectedQuestionIdx]?.student_answers &&
              !isLoading && (
                <Button onClick={() => dispatch(openExamHintkModel())}>
                  Hint
                </Button>
              )}
            {/* questions */}
            {isLoading && <CircularProgress isIndeterminate />}
            {!examQuestions[selectedQuestionIdx]?.student_answers &&
              !isLoading && (
                <ExamQuestions
                  data={examQuestions[selectedQuestionIdx]}
                  setAnswers={setAnswers}
                  answers={answers}
                  selectedQuestionIdx={selectedQuestionIdx}
                />
              )}
            {examQuestions[selectedQuestionIdx]?.student_answers &&
              !isLoading && (
                <ExamSolvedQuestions
                  data={examQuestions[selectedQuestionIdx]}
                  correctionArr={examQuestions[selectedQuestionIdx]?.answers}
                  selectedQuestionIdx={selectedQuestionIdx}
                  studentAnswer={
                    examQuestions[selectedQuestionIdx]?.student_answers
                  }
                />
              )}
            {/* submit */}
            <HStack mt="10px">
              {selectedQuestionIdx > 0 && (
                <Button onClick={handelPrevQuestion} bg="main_1" color="white">
                  {" "}
                  Prev Question
                </Button>
              )}

              <Button onClick={handelNextQuestion} bg="main_1" color="white">
                {" "}
                next Question
              </Button>

              {!examQuestions[selectedQuestionIdx]?.student_answers && (
                <Button onClick={handelSendAnswers} bg="main_1" color="white">
                  {" "}
                  Send answers
                </Button>
              )}
            </HStack>
          </VStack>
        </Stack>
      </Container>
      <ExamAnswersLoading />
      <ExamHint
        videoId={videoId}
        model_answer_image={
          examQuestions?.[selectedQuestionIdx]?.model_answer_image
        }
      />
    </Layout>
  );
};

export default StudyWithEnoughSolveExam;
