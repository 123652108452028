import axiosInstance from "../../../helpers/api";

export const getAllChapters = (body) => {
    return axiosInstance.get('/admin/chapters', body);
};

export const editChapters = (formData) => {
    return axiosInstance.post(`/admin/chapters/${formData.id}`, formData.formData);
};

export const restrictChapters = (formData) => {
    return axiosInstance.post(`/admin/chapters/restrict/${formData.id}`, formData.formData);
};

export const activeChapters = (formData) => {
    return axiosInstance.post(`/admin/chapters/activate/${formData.id}`, formData.formData);
};

export const createChapters = (formData) => {
    return axiosInstance.post('/admin/chapters', formData);
};

export const deleteChapter = (id) => {
    return axiosInstance.delete(`/admin/chapters/${id}`);
};
