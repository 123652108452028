import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import Select from "react-select";
import {
  examAttendaceRequest,
  unpaidReportRequest,
  videoReportRequest,
} from "../../../modules/admin-modules/reports/Actions";
import Layout from "../../../components/dashboard/Layout";
import { getAllExamsRequest } from "../../../modules/admin-modules/exams/Actions";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { getAllVideosRequest } from "../../../modules/admin-modules/videos/Actions";
import { CSVLink } from "react-csv";

const UnpaiedReport = ({ setAdd, setSelectedData, searchTerm }) => {
  const dispatch = useDispatch();
  const [videoId, setVideoId] = useState("");

  useEffect(() => {
    if (videoId) dispatch(unpaidReportRequest(videoId));
  }, [videoId]);

  useEffect(() => {
    dispatch(getAllVideosRequest());
  }, []);
  const videos = useSelector((state) => state.videos.videos);
  const videosOptions = videos?.map((data) => {
    return { value: data.id, label: data.title };
  });

  const unpaidReport = useSelector((state) => state.reports.unpaidReport);
  const iaLoading = useSelector((state) => state.reports.iaLoading);

  const [offset, setPage] = useState(0);

  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const headers = [
    { label: "name", key: "name" },
    { label: "group_name", key: "group_name" },
    { label: "phone", key: "phone" },
    { label: "parent_phone", key: "parent_pone" },
  ];
  const Data = unpaidReport?.map((data, index) => {
    return {
      ...data,
      index: `#${index + 1}`,
      id: data?.id,
      name: data?.name,
      group_name: data?.group?.name,
      phone: data?.phone,
    };
  });
  const columns = [
    {
      Header: "رقم الطالب",
      accessor: "index",
    },
    {
      Header: "اسم الطالب",
      accessor: "name",
    },
    {
      Header: " المجموعة",
      accessor: "group_name",
    },
    {
      Header: " رقم ولي الامر ",
      accessor: "parent_pone",
    },
    {
      Header: " رقم  الطالب",
      accessor: "phone",
    },
  ];

  return (
    <Layout>
      <div style={{ minHeight: "400px" }}>
        <Flex
          w="100%"
          my="30px"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          zIndex="10000000"
        >
          <Text fontSize="24px" fontWeight="600" color="main_1">
            تقرير مشاهدات الفيديوهات
          </Text>
          <Flex w="30%" justifyContent="space-between" alignItems="center">
            <Select
              options={videosOptions}
              isMulti={false}
              onChange={(data) => {
                setVideoId(data?.value);
              }}
              styles={colourStyles}
              placeholder="Select Video"
            />
            <Box
              mx="10px"
              border="1px solid gray"
              borderRadius="8px"
              padding="10px"
            >
              <CSVLink
                headers={headers}
                data={Data?.length != 0 ? Data : []}
                filename={"video-views-report.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </Box>
            <LogoutMenu />
          </Flex>
        </Flex>

        <CTable
          selectedData={viewData}
          Columns={columns}
          Data={Data}
          path={"openModel"}
          Actions
          Title={``}
          subTitle=""
          btnTitle=""
          placeHolder=""
          noSearchBar={true}
          noFilter={true}
          footerBtnTitle
          filterList
          addButton
          addButtonTitle=""
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={pageNumber}
          setPageNumber={setPageNumber}
          noSecondSearchBar={true}
          isLoading={iaLoading}
          perPage={size}
          totalPage={Math.ceil(10 / 50)}
        />
      </div>
    </Layout>
  );
};

export default UnpaiedReport;
