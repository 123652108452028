import React, { useState } from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllLecturesRequest } from "../../modules/admin-modules/lectures/Actions";
import axiosInstance from "../../helpers/api";
import Layout from "../../components/dashboard/Layout/index";
import {
  editStudentRequest,
  getStudentRequest,
} from "../../modules/admin-modules/students/Actions";

const ScanCode = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [result, setResult] = useState(null);
  const path = window.location.pathname.split("/");

  const lectures = useSelector((state) => state.lectures.lectures);
  const student = useSelector((state) => state.students.student);

  useEffect(() => {
    dispatch(getStudentRequest(path[2]));
  }, [path[2]]);
  const handelResult = () => {
    if (result && path[3] == "attendence" && path[2]) {
      try {
        axiosInstance.post(`/admin/attendence/scan`, {
          lec_id: result,
          user_id: path[2],
          attend: 1,
        });

        toast.success("Edit Successfully");
      } catch (error) {
        toast.error(error);
      } finally {
      }
    } else {
      toast.error("لم يتم الاختيار");
    }
  };

  useEffect(() => {
    if (path[3] == "attendence") {
      dispatch(getAllLecturesRequest());
      setData(lectures);
    }
  }, [path[3]]);
  return (
    <Layout>
      <Box w="100%">
        <VStack
          w={{ base: "100%", md: "50%" }}
          m="70px auto"
          padding="8px"
          boxShadow="md"
          border="1px solid gray"
          borderRadius="8px"
          alignItems="flex-start"
        >
          <HStack>
            <Text>الاسم : </Text>
            <Text>{student?.name}</Text>
          </HStack>
          <HStack>
            <Text>المجموعة : </Text>
            <Text>{student?.group?.name}</Text>
          </HStack>
          <HStack>
            <Text> الصف : </Text>
            <Text>{student?.grade?.name}</Text>
          </HStack>
          <HStack>
            <Text>رقم الهاتف : </Text>
            <Text>{student?.phone}</Text>
          </HStack>
          <HStack>
            <Text>رقم هاتف ولي الامر : </Text>
            <Text>{student?.parent_phone}</Text>
          </HStack>
        </VStack>
        <Heading textAlign="center" pt="65px">
          اختر الحصة
        </Heading>
        <VStack h="100vh" alignItems="center" mt="20px">
          <Select
            w="50%"
            bg="white"
            onChange={(e) => setResult(e.target.value)}
            dir="ltr"
          >
            <option value="">اختر</option>

            {lectures?.map((val) => {
              return <option value={val.id}>{val?.title}</option>;
            })}
          </Select>
          <Button onClick={handelResult}>حفظ التغيرات</Button>
        </VStack>
      </Box>
    </Layout>
  );
};

export default ScanCode;
