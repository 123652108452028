import {
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  Stack,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/student/Layout/layout";
import ExamEllipses from "../../../containers/student/exams/exam-ellipses";
import ExamQuestions from "../../../containers/student/exams/exam-questions";
import Timer from "../../../containers/student/exams/timer";
import ExamAnswersLoading from "../../../containers/student/models/exam-answers-loading";
import {
  closeExamAnswersLoading,
  openExamAnswersLoading,
} from "../../../modules/admin-modules/models/Actions";
import {
  getExamQuestionsRequest,
  getExamsByIdRequest,
  sendAnswersRequest,
} from "../../../modules/students-modules/exams/Actions";
import { getStudentExamQuestionsRequest } from "../../../modules/students-modules/student-exams/Actions";

const SolveExam = () => {
  const [examQuestions, setExamQuestions] = useState([]);
  const dispatch = useDispatch();
  const queryString = require("query-string");
  const parsed = queryString.parse(window.location.search);
  const examId = window.location.pathname.includes("student")
    ? window.location.pathname.split("/")[3]
    : window.location.pathname.split("/")[2];
  const examQuestionsData = useSelector(
    (state) => state.chapterExams.examQuestions
  );
  const studentExamQuestions = useSelector(
    (state) => state.studentExams.examQuestions
  );

  const [answers, setAnswers] = useState([]);
  const [selectedQuestionIdx, setSelectedQuestionIdx] = useState(0);
  const exam = useSelector((state) => state.chapterExams.exam);
  const isLoading = useSelector((state) => state.chapterExams.isLoading);
  // useBeforeunload((event) => {
  //   if (Object.keys(answers)?.length != examQuestions?.length) {
  //     event.preventDefault();
  //     return "you will lose your data";
  //   }
  // });
  useEffect(() => {
    if (examId) {
      dispatch(getExamsByIdRequest(examId));
      if (window.location.pathname.includes("student")) {
        dispatch(getStudentExamQuestionsRequest(examId));
      } else {
        dispatch(getExamQuestionsRequest(examId));
      }
    }
  }, [examId, window.location.pathname]);

  useEffect(() => {
    if (window.location.pathname.includes("student")) {
      setExamQuestions(studentExamQuestions);
    } else {
      setExamQuestions(examQuestionsData);
    }
  }, [studentExamQuestions, examQuestionsData]);

  const handelNextQuestion = () => {
    if (selectedQuestionIdx < 0)
      setSelectedQuestionIdx(examQuestions?.length - 1);
    else if (selectedQuestionIdx >= examQuestions?.length - 1)
      setSelectedQuestionIdx(0);
    else setSelectedQuestionIdx((s) => s + 1);
  };
  const handelPrevQuestion = () => {
    if (selectedQuestionIdx > examQuestions?.length - 1)
      setSelectedQuestionIdx(0);
    if (selectedQuestionIdx <= 0)
      setSelectedQuestionIdx(examQuestions?.length - 1);
    else setSelectedQuestionIdx((s) => s - 1);
  };

  const handelSendAnswers = async () => {
    dispatch(openExamAnswersLoading());
    const action = () => {
      dispatch(closeExamAnswersLoading());
      window.localStorage.removeItem("startTime");
      window.location.pathname.includes("student")
        ? (window.location.href = "/student/exams")
        : (window.location.href = "/exams/sections");
    };

    let formData = new FormData();
    await formData.append("exam_id", examId);

    await answers?.map((data, index) =>
      formData.append(`answers[${index}][question_id]`, data?.question_id)
    );
    await answers?.map((data, index) =>
      formData.append(`answers[${index}][answer_id]`, data?.answer_id)
    );

    dispatch(sendAnswersRequest(formData, action));
  };

  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      {/* <Beforeunload onBeforeunload={() => "You’ll lose your data!"} /> */}

      <Container
        maxW="container.xl"
        h="100%"
        my="100px"
        mb="165px"
        w="100%"
        onSelect={(e) => e.preventDefault()}
        onMouseDown={(e) => e.preventDefault()}
      >
        <VStack spacing={"70px"} h="100%" w="100%" mt={20}>
          <Heading
            as="h3"
            color="main_1"
            textAlign="center"
            w="100%"
            textTransform="capitalize"
          >
            {parsed?.name}
          </Heading>
          {exam?.timer == 1 && (
            <Timer time={exam?.time} handelSendAnswers={handelSendAnswers} />
          )}
          {/* questions polts */}
          <HStack>
            <Icon
              as={RiArrowDropLeftLine}
              onClick={handelPrevQuestion}
              fontSize="35px"
            />

            <ExamEllipses
              answeredQ={Object.keys(answers)}
              questionsCount={examQuestions?.length}
              setSelectedQuestionIdx={setSelectedQuestionIdx}
              selectedQuestionIdx={selectedQuestionIdx}
            />
            <Icon
              as={RiArrowDropRightLine}
              onClick={handelNextQuestion}
              fontSize="35px"
            />
          </HStack>

          <Stack flexDir={{ base: "column", md: "row" }} alignItems="end">
            {/* questions */}
            <ExamQuestions
              data={examQuestions[selectedQuestionIdx]}
              setAnswers={setAnswers}
              answers={answers}
              selectedQuestionIdx={selectedQuestionIdx}
            />

            {/* submit */}
            <HStack mt="10px">
              <Button onClick={handelNextQuestion} bg="main_1" color="white">
                {" "}
                Next Question
              </Button>
              {Object.keys(answers)?.length == examQuestions?.length ? (
                <Button onClick={handelSendAnswers} bg="main_1" color="white">
                  {" "}
                  Send answers
                </Button>
              ) : null}
            </HStack>
          </Stack>
        </VStack>
      </Container>
      <ExamAnswersLoading />
    </Layout>
  );
};

export default SolveExam;
