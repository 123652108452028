import {
  Container,
  Heading,
  HStack,
  VStack,
  Box,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  Flex,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/student/Layout/layout";
import { getChapterExamRequest } from "../../../modules/students-modules/exams/Actions";
import VideoCard from "../../../containers/student/video/video";
import ExamCard from "../../../containers/student/studyWithEnough/ExamCard";
import AddVoucher from "../../../containers/student/models/add-voucher";

const StudyWithEnoughExams = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chapterExams = useSelector((state) => state.chapterExams.chapterExam);
  const isLoading = useSelector((state) => state.chapterExams.isLoading);
  const [selectedindex, setSelectedIndex] = useState(null);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getChapterExamRequest());
  }, []);

  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" mt="100px" mb="165px" w="100%">
        <HStack w={"100%"}>
          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            Asquera Practice
          </Heading>
        </HStack>
        <Stack
          my="100px"
          w="100%"
          flexDirection={{ base: "column", md: "row" }}
          flexWrap="wrap"
          alignItems="start"
          justifyContent="space-between"
          gap={5}
        >
          <Flex w={{ base: "100%", md: "49%" }}>
            <Accordion defaultIndex={[0]} allowMultiple w="100%">
              <AccordionItem>
                <h2>
                  <AccordionButton
                    bg="main_1"
                    color="white"
                    borderRadius="8px"
                    _focus={{ outline: "none" }}
                    _hover={{ bg: "main_1" }}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Exams
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  {chapterExams?.exams?.map((exam, idx) => {
                    return <ExamCard exam={exam} key={idx} />;
                  })}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Flex>

          <Flex w={{ base: "100%", md: "49%" }}>
            <Accordion defaultIndex={[0]} allowMultiple w="100%">
              <AccordionItem>
                <h2>
                  <AccordionButton
                    bg="main_1"
                    color="white"
                    borderRadius="8px"
                    _focus={{ outline: "none" }}
                    _hover={{ bg: "main_1" }}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Videos{" "}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {chapterExams?.videos?.map((data, idx) => {
                    return (
                      <Box
                        my={2}
                        borderRadius="8px"
                        w="100%"
                        cursor="pointer"
                        boxShadow={
                          selectedData?.id == data?.id
                            ? "2px 3px 10px #80808045"
                            : "none"
                        }
                        key={idx}
                        onClick={() => {
                          setSelectedData(data);

                          if (
                            data?.max_allowed_views <=
                              data?.views_count?.views_count ||
                            (data?.suspended == 1 &&
                              data?.user_video_suspended != 0)
                          ) {
                          } else {
                            navigate(
                              `/asquera-practice/video-preview/${data?.id}?title=${data.title}`
                            );
                            setSelectedIndex(idx);
                          }
                        }}
                      >
                        <VideoCard
                          data={data}
                          index={idx}
                          selectedindex={selectedindex}
                        />
                      </Box>
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Flex>
        </Stack>
        <AddVoucher video_id={selectedData?.id} />
      </Container>
    </Layout>
  );
};

export default StudyWithEnoughExams;
