import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import MainInput from "../../../../components/Form/input";
import MainModel from "../../../../components/Models/main-model";
import { getAllChaptersRequest } from "../../../../modules/admin-modules/chapters/Actions";
import { editExamQuestionRequest } from "../../../../modules/admin-modules/exams/Actions";
import { closeCreateQuestionModel } from "../../../../modules/admin-modules/models/Actions";
import {
  createQuestionBankRequest,
  editQuestionBankRequest,
} from "../../../../modules/admin-modules/questions-bank/Actions";

import "react-quill/dist/quill.snow.css";

const AddQuestion = ({ isAdd, data, setSelectedData }) => {
  const baseUrl = "https://asquera.com/asquera";

  const dispatch = useDispatch();

  const [selected, setSelected] = useState("");
  const [questionAnswerImg, setQuestionAnswerImg] = useState("");

  const [answers, setAnswers] = useState([]);
  const [rightAnswer, setRightAnwser] = useState();

  const openQuestions = useSelector((state) => state.model.isOpen16);
  const chapters = useSelector((state) => state.chapters.chapters);
  const isLoading = useSelector((state) => state.questionsBank.isLoading);
  useEffect(() => {
    if (openQuestions) {
      dispatch(getAllChaptersRequest());
    }
  }, [openQuestions]);

  useEffect(() => {
    if (!isAdd) {
      setAnswers(data?.answers);
      const filter = data?.answers
        ?.map((data, index) => {
          if (data?.right_answer == 1) return index;
        })
        .filter((data) => data != undefined);
      if (filter?.length > 0) {
        setRightAnwser(filter[0]);
      }
    } else if (isAdd) {
      setAnswers([]);
      setRightAnwser(null);
    }
  }, [isAdd, data]);
  const chaptersOptions = chapters?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: !isAdd ? data : { difficulty_level: "1", mark: 1 },
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " إضافة سؤال" : "تعديل  سؤال"}
      </Heading>
    </Box>
  );

  const onSubmit = async (val) => {
    const values = {
      ...val,
      difficulty_level: val?.difficulty_level ? val?.difficulty_level : "0",
    };
    const action = () => {
      dispatch(closeCreateQuestionModel());
      setSelectedData({});
      reset();
      setAnswers([]);
    };

    if (isAdd) {
      let formData = await new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }

      answers.map((data, idx) =>
        formData.append(`answers[${idx}][answer]`, data?.answer)
      );
      answers.map((data, idx) =>
        formData.append(
          `answers[${idx}][right_answer]`,
          data?.right_answer ? data?.right_answer : 0
        )
      );
      const filteredAnswers = answers?.filter(
        (data, idx) => data?.right_answer == 1
      );

      if (values.chapter_id && answers?.length == 4) {
        if (filteredAnswers?.length > 0)
          await dispatch(createQuestionBankRequest(formData, action));
        else toast.error("اختر اجابة صحيحة ");
      } else {
        toast.error("  قم بمراجعة الاجابات او  اختر الفصل ");
      }
    } else {
      let formData = await new FormData();
      const keys = [
        "select",
        "answers",
        "index",
        "delete",
        "edit",
        "chapter",
        "bookmark",
        "Actions",
        "deleted_at",
      ];
      formData.append("_method", "PUT");

      const totel = { ...data, ...values };
      answers.map((data, idx) =>
        formData.append(`answers[${idx}][id]`, data?.id)
      );

      answers.map((data, idx) =>
        formData.append(`answers[${idx}][answer]`, data?.answer)
      );
      answers.map((data, idx) =>
        formData.append(`answers[${idx}][right_answer]`, data?.right_answer)
      );

      for (let key in totel) {
        if (!keys.includes(key)) formData.append(key, totel[key]);
      }
      dispatch(
        editQuestionBankRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };
  const closeFunc = () => {
    dispatch(closeCreateQuestionModel());
    setSelectedData({});
    setAnswers([]);
    reset();
  };
  const handelEditQuestion = (answerID, index) => {
    //console.log(answerID);
    //console.log(answers[index]);
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("question_id", data?.id);
    formData.append("right_answer", answers[index]?.right_answer);
    formData.append("answer", answers[index]?.answer);
    dispatch(
      editExamQuestionRequest({
        formData: formData,
        id: answerID,
      })
    );
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openQuestions}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex w="100%" flexWrap="wrap" justifyContent="space-between" gap="6">
          <Box w="100%" dir="rtl">
            <FormLabel>نص القطعة:</FormLabel>
            <Textarea
              name="comprehension"
              placeholder="ادخل نص القطعة التي تسبق السؤال"
              defaultValue={data?.comprehension}
              error={errors?.comprehension?.message}
              {...register("comprehension")}
            />
          </Box>
          <Box w="100%">
            <Text dir="rtl"> نص السؤال </Text>
            <ReactQuill
              theme="snow"
              register={register}
              name="question"
              defaultValue={data?.question}
              onChange={(val) => setValue("question", val)}
            />
          </Box>
          <Box w="100%" dir="rtl">
            <MainInput
              label="درجة السؤال"
              defaultValues={data?.mark || 1}
              error={errors?.mark?.message}
              placeholder="ادخل درجة السؤال"
              register={register}
              name="mark"
              type="number"
              required={false}
            />
          </Box>
          <HStack w="100%" my="20px" justifyContent="space-between" dir="rtl">
            <HStack w="50%">
              <FormLabel>صورة السؤال:</FormLabel>
              <Button
                bg="main_1"
                color="white"
                borderRadius="8px"
                textAlign="center"
                position="relative"
              >
                <Text>
                  {selected
                    ? selected
                    : data?.image
                    ? `file_1`
                    : "اختر ملف ليتم رفعه"}
                </Text>
                <Input
                  onChange={(e) => {
                    setValue("image", e.target.files[0]);
                    setSelected((s) => e.target.files[0].name);
                  }}
                  type="file"
                  placeholder="اختر ملف ليتم رفعه"
                  opacity="0"
                  position="absolute"
                  w="100%"
                  h="100%"
                />
              </Button>
            </HStack>
            {data?.image && (
              <img src={`${baseUrl}/${data?.image}`} width="200px" />
            )}
          </HStack>

          <HStack w="100%" my="20px" justifyContent="space-between" dir="rtl">
            <HStack w="50%">
              <FormLabel>صورة اجابة السؤال:</FormLabel>
              <Button
                bg="main_1"
                color="white"
                borderRadius="8px"
                textAlign="center"
                position="relative"
              >
                <Text>
                  {questionAnswerImg
                    ? questionAnswerImg
                    : data?.model_answer_image
                    ? `file_1`
                    : "اختر ملف ليتم رفعه"}
                </Text>
                <Input
                  onChange={(e) => {
                    setValue("model_answer_image", e.target.files[0]);
                    setQuestionAnswerImg((s) => e.target.files[0].name);
                  }}
                  type="file"
                  placeholder="اختر ملف ليتم رفعه"
                  opacity="0"
                  position="absolute"
                  w="100%"
                  h="100%"
                />
              </Button>
            </HStack>
            {data?.image && (
              <img
                src={`${baseUrl}/${data?.model_answer_image}`}
                width="200px"
              />
            )}
          </HStack>

          <Box w="100%" dir="rtl">
            <FormLabel> مستوي السؤال : </FormLabel>

            <Stack
              direction="row"
              role="group"
              aria-labelledby="my-radio-group"
              spacing="20px"
            >
              <label>
                <input
                  {...register("difficulty_level")}
                  type="radio"
                  value="1"
                  defaultChecked={data?.difficulty_level == 1 ? true : false}
                />
                notes
              </label>
              <label>
                <input
                  {...register("difficulty_level")}
                  type="radio"
                  value="2"
                  defaultChecked={data?.difficulty_level == 2 ? true : false}
                />
                كتاب المدرسة
              </label>
              <label>
                <input
                  {...register("difficulty_level")}
                  type="radio"
                  value="3"
                  defaultChecked={data?.difficulty_level == 3 ? true : false}
                />
                hard questions
              </label>
              <label>
                <input
                  {...register("difficulty_level")}
                  type="radio"
                  value="4"
                  defaultChecked={data?.difficulty_level == 4 ? true : false}
                />
                امتحانات السنين السابقة
              </label>
              <label>
                <input
                  {...register("difficulty_level")}
                  type="radio"
                  value="5"
                  defaultChecked={data?.difficulty_level == 5 ? true : false}
                />
                Revision
              </label>
            </Stack>
          </Box>

          <Box w="100%" dir="rtl">
            <Box w="45%">
              <FormLabel>الفصول : </FormLabel>
              <Select
                options={chaptersOptions}
                onChange={(data) => setValue("chapter_id", data.value)}
                styles={colourStyles}
                placeholder="الصف"
                defaultValue={{
                  label: data?.chapter?.name,
                  value: data?.chapter?.id,
                }}
              />
            </Box>
          </Box>

          <Box w="100%" dir="rtl">
            <FormLabel>الاجابات : </FormLabel>
            {[...Array(4)].map((val, index) => {
              return (
                <HStack my="10px">
                  <Input
                    defaultValue={
                      data?.answers?.length > 0 && !isAdd
                        ? data?.answers[index]?.answer
                        : null
                    }
                    placeholder="اضف الإجابة  "
                    size="md"
                    onChange={(e) => {
                      const newAnswers = answers;
                      newAnswers[index] = {
                        ...newAnswers[index],
                        answer: e.target.value,
                      };
                      setAnswers(newAnswers);
                    }}
                  />

                  <Switch
                    size="lg"
                    // isChecked={defaultChecked(index)}
                    isChecked={rightAnswer == index ? true : false}
                    color="#010038"
                    onChange={(e) => {
                      setRightAnwser(index);
                      let newAnswers = answers;
                      let mappedData = newAnswers?.map((val, idx) => {
                        return { ...val, right_answer: idx == index ? 1 : 0 };
                      });
                      setAnswers(mappedData);
                    }}
                  />

                  {/* {!isAdd && (
                    <Button
                      onClick={() =>
                        handelEditQuestion(data?.answers[index]?.id, index)
                      }
                    >
                      Edit
                    </Button>
                  )} */}
                </HStack>
              );
            })}
          </Box>
        </Flex>
      </form>
    </MainModel>
  );
};

export default AddQuestion;
