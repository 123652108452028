import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const StudentWrapper = ({ children, roles }) => {
  const lang = localStorage.getItem("language");
  const data = useSelector((state) => state.user.userData);
  const token = window.localStorage.getItem("token");

  useEffect(() => {
    if (!token || (data?.role && !roles.includes(data?.role[0].name))) {
      window.localStorage.clear();

      window.location.href = "/login";
    }
  }, [roles, data, token]);
  return <Box dir={lang == "ar" ? "rtl" : "ltr"}>{children}</Box>;
};

export default StudentWrapper;
